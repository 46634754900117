import React, {useState, useRef, useEffect} from 'react'

const ListaDesplegableConSelector = ({
  estilosPersonalizados,
  estilosInput,
  textoPorDefecto,
  datosParaMostrar,
  placeholder,
  manejarOpcionSeleccionada,
  textoToolTip = '',
}) => {
  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState('')
  const [abierto, setAbierto] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current && abierto) {
      inputRef.current.focus()
    }
  }, [abierto])

  return (
    <div
      className={` flex ${estilosPersonalizados} justify-center items-center flex-wrap text-13px mt-10 `}
    >
      <div className="w-full font-medium relative -mt-10">
        <span
          className={`absolute w-full text-center z-50 py-3 px-3 rounded-lg -top-12 text-white ${
            !selected && textoToolTip
              ? 'visible opacity-100'
              : 'invisible opacity-0'
          } ml-1  bg-cendiatra-verde-2 shadow-lg shadow-slate-600`}
        >
          {textoToolTip}
        </span>

        <button
          onClick={() => setAbierto(!abierto)}
          data-dropdown-toggle="dropdown"
          className={`w-full p-2 flex items-center justify-between border ${estilosInput} rounded-lg ${
            !selected ? 'text-cendiatra-gris-2' : 'text-cendiatra-verde-2'
          }`}
        >
          {selected
            ? selected?.length > 25
              ? selected?.substring(0, 25) + '...'
              : selected
            : textoPorDefecto}
          <svg
            class="w-2.5 h-2.5 ms-3 text-cendiatra-gris-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <ul
          className={`bg-white mt-2 overflow-y-auto absolute w-full  ${
            abierto
              ? 'max-h-60 border border-cendiatra-verde-2 rounded-lg'
              : 'max-h-0'
          } `}
        >
          <div className="flex w-full items-center px-2 sticky top-0 bg-white ">
            <input
              type="text"
              ref={inputRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value.toLowerCase())}
              placeholder={placeholder}
              className=" placeholder:text-cendiatra-gris-2 p-2 outline-none w-full  border-b-2 border-cendiatra-verde-2"
            />
          </div>
          <li
            key="-1"
            value=""
            className={`p-2 text-sm text-cendiatra-gris-3 hover:bg-cendiatra-verde-2 hover:text-white mt-1 block`}
            onClick={() => {
              setSelected('')
              setAbierto(false)
              setInputValue('')
              manejarOpcionSeleccionada('')
            }}
          >
            {' '}
            {textoPorDefecto}{' '}
          </li>
          {datosParaMostrar?.map((ciudad) => (
            <li
              key={ciudad?.Id}
              className={`p-2 text-sm hover:bg-cendiatra-verde-2 hover:text-white mt-1
              ${
                ciudad?.Nombre?.toLowerCase() === selected?.toLowerCase() &&
                'bg-cendiatra-verde-2 text-white'
              }
                ${
                  ciudad?.Nombre?.toLowerCase().startsWith(inputValue)
                    ? 'block'
                    : 'hidden'
                }`}
              onClick={() => {
                if (ciudad?.Nombre?.toLowerCase() !== selected.toLowerCase()) {
                  setSelected(ciudad?.Nombre)
                  setAbierto(false)
                  setInputValue('')
                }
                manejarOpcionSeleccionada(ciudad)
              }}
            >
              {ciudad?.Nombre}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default ListaDesplegableConSelector
