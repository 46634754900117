import React, {useState} from 'react'

const CampoFiltrar = ({
  titulo,
  estilosPersonalizados,
  textoTitulo,
  estilosInput,
  placeholder,
  tipo,
  valorDelCampoFiltro,
  desactivarOtroFiltro,
  informacionFiltrada,
  handleChange,
  handleOptionChange,
  id,
}) => {
  const [enfocado, setEnfocado] = useState(false)

  const manejarEnfocado = () => {
    setEnfocado(true)
  }

  const manejarDesenfocado = (e) => {
    setEnfocado(false)
  }

  return (
    <div
      className={` flex ${estilosPersonalizados} justify-center items-center flex-wrap text-13px `}
    >
      <label
        className={`flex ${
          textoTitulo ? textoTitulo : ''
        } text-cendiatra w-full my-5px `}
      >
        {titulo}
      </label>
      <input
        className={`appearance-none border ${estilosInput}`}
        placeholder={placeholder}
        type={tipo}
        autoComplete="off"
        onChange={(e) => handleChange(e)}
        onFocus={manejarEnfocado}
        onBlur={manejarDesenfocado}
        value={valorDelCampoFiltro}
        disabled={desactivarOtroFiltro != '' ? true : false}
        id={id ? id : null}
      />
      <div className="relative w-full -mt-5 z-50">
        {enfocado && informacionFiltrada && informacionFiltrada.length > 0 && (
          <div className="bg-white border border-cendiatra w-full absolute">
            {informacionFiltrada.map((elemento) => (
              <div
                className="bg-cendiatra-gris-5 text-center"
                onMouseDown={() => handleOptionChange(elemento)}
                key={elemento.Id}
                tabIndex="0"
              >
                <span className="text-cendiatra-gris-3 uppercase">
                  {elemento.Nombre}
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default CampoFiltrar
