import React, {useEffect, useState} from 'react'
import {ListadoOrdenesYAtenciones} from '../../../componentes/RecursosHumanos'
import {useMsal} from '@azure/msal-react'
import {TEXTO_VISUAL} from '../../../constantes'
import {FachadaObtenerOrdenesGestionOrdenes} from '../../../microservicios'
import moment from 'moment'
import {ObtenerEmpresaPadreYempresasHijas} from '../../../cache/servicios-cache'
import {ordenarListaOrdenes, agregarFormatoFecha} from '../../../utils'

const GestionDeOrdenes = () => {
  const {accounts} = useMsal()
  const rolUsuario = accounts[0].idTokenClaims?.country ?? ''
  const idEmpresa = accounts[0].idTokenClaims?.city ?? ''
  const [listaOrdenes, setListaOrdenes] = useState([])

  const filtrosIniciales = {
    tamano: 10,
    pagina: 1,
    filtros: {
      centroCostosId: null,
      documento: '',
      nombres: '',
      apellidos: '',
      fechaDesde: moment().format('YYYY-MM-DD 00:00:00'),
      fechaHasta: moment().format('YYYY-MM-DD HH:mm:ss'),
      estadoId: '',
      usuariosIds: [],
      empresasIds: [idEmpresa],
    },
  }

  const [dataFiltros, setDataFiltros] = useState(filtrosIniciales)
  const [actualizarInformacion, setActualizarInformacion] = useState(true)
  const [informacionPaginacion, setInformacionpaginacion] = useState({})
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState({})
  const [empresasFiltradas, setEmpresasFiltradas] = useState([])
  const [estadosOrden, setEstadosOrden] = useState([])

  const obtenerInformacionInicial = async () => {
    try {
      const estadosOrdenes = [
        {Id: 1, Nombre: 'BORRADOR'},

        {Id: 2, Nombre: 'ABIERTA'},

        {Id: 5, Nombre: 'CANCELADA'},

        {Id: 6, Nombre: 'VENCIDA'},
      ]

      setEstadosOrden(estadosOrdenes)

      const payload = {
        tamano: dataFiltros.tamano,
        pagina: dataFiltros.pagina,
        filtros: {
          centroCostosId: dataFiltros.filtros.centroCostosId,
          documento: dataFiltros.filtros.documento,
          nombres: dataFiltros.filtros.nombres,
          apellidos: dataFiltros.filtros.apellidos,
          fechaDesde:
            dataFiltros.filtros.documento ||
            dataFiltros.filtros.nombres ||
            dataFiltros.filtros.apellidos
              ? ''
              : dataFiltros.filtros.fechaDesde,
          fechaHasta:
            dataFiltros.filtros.documento ||
            dataFiltros.filtros.nombres ||
            dataFiltros.filtros.apellidos
              ? ''
              : dataFiltros.filtros.fechaHasta,
          estadoId: dataFiltros.filtros.estadoId,
          usuariosIds: dataFiltros.filtros.usuariosIds,
          empresasIds: dataFiltros.filtros.empresasIds,
        },
      }
      let ordenes = await FachadaObtenerOrdenesGestionOrdenes(payload)

      if (rolUsuario === TEXTO_VISUAL.HOME.ROL.MEDICO && ordenes?.data) {
        let ordenesFiltradas = ordenes?.listaDeOrdenes.filter(
          (element) => element.estado === TEXTO_VISUAL.ORDENES.ESTADO.ATENDIDA
        )
        setListaOrdenes(ordenesFiltradas)
      } else {
        setListaOrdenes(ordenarListaOrdenes(ordenes?.listaDeOrdenes))
      }
      setInformacionpaginacion(ordenes?.informacionPaginacion)
    } catch (error) {
      console.log(error)
    }
  }
  const filtrarInformacion = () => {
    setActualizarInformacion(!actualizarInformacion)
  }

  const limpiarFiltros = () => {
    setDataFiltros(filtrosIniciales)
    filtrarInformacion()
    setEmpresaSeleccionada({
      ...empresaSeleccionada,
      nombreEmpresa: '',
    })
    setEmpresasFiltradas([])
  }

  const manejarCambioEnFiltro = (e) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: 1,
        filtros: {
          ...prev.filtros,
          [e.target.name]: agregarFormatoFecha(e),
        },
      }
    })
  }
  const manejarCambioDePagina = (numeroPagina) => {
    setDataFiltros((prev) => {
      return {
        ...prev,
        pagina: numeroPagina,
      }
    })
  }

  const filtrarEmpresas = async (e) => {
    setEmpresaSeleccionada({
      ...empresaSeleccionada,
      nombreEmpresa: e.target.value,
    })
    if (e.target.value.length >= 2) {
      ObtenerEmpresaPadreYempresasHijas(idEmpresa, e.target.value).then(
        (res) => {
          setEmpresasFiltradas(res)
        }
      )
    } else {
      setEmpresasFiltradas([])
      setDataFiltros((prev) => {
        return {
          ...prev,
          filtros: {
            ...prev.filtros,
            centroCostosId: null,
          },
        }
      })
    }
  }

  const manejarEmpresaSeleccionada = (empresa) => {
    setEmpresaSeleccionada({
      ...empresaSeleccionada,
      nombreEmpresa: empresa.Nombre,
    })
    setDataFiltros((prev) => {
      return {
        ...prev,
        filtros: {
          ...prev.filtros,
          centroCostosId: empresa.Id,
        },
      }
    })
  }

  const filtrosExternos = [
    {
      id: 1,
      estilos: 'w-2/12',
      titulo: 'No de documento',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'documento',
      formato: 'input',
      valor: dataFiltros.filtros.documento,
    },
    {
      id: 6,
      estilos: 'w-2/12',
      titulo: 'Nombre',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'nombres',
      formato: 'input',
      valor: dataFiltros.filtros.nombres,
    },
    {
      id: 7,
      estilos: 'w-2/12',
      titulo: 'Apellido',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'apellidos',
      formato: 'input',
      valor: dataFiltros.filtros.apellidos,
    },
    {
      id: 2,
      estilos: 'w-2/12',
      titulo: 'Fecha inicial',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaDesde',
      fechaMaxima: moment(dataFiltros.filtros.fechaHasta).format('YYYY-MM-DD'),
      formato: 'input',
      valor:
        dataFiltros.filtros.documento ||
        dataFiltros.filtros.nombres ||
        dataFiltros.filtros.apellidos
          ? ''
          : moment(dataFiltros.filtros.fechaDesde).format('YYYY-MM-DD'),
      estiloPersonalizado: `${
        dataFiltros.filtros.documento ||
        dataFiltros.filtros.nombres ||
        dataFiltros.filtros.apellidos
          ? 'pointer-events-none bg-cendiatra-gris-4'
          : ''
      }`,
    },
    {
      id: 3,
      estilos: 'w-2/12',
      titulo: 'Fecha Final',
      tipo: 'date',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'fechaHasta',
      fechaMinima:
        moment(dataFiltros.filtros.fechaDesde).format('YYYY-MM-DD') ===
        moment().format('YYYY-MM-DD')
          ? ''
          : moment(dataFiltros.filtros.fechaDesde).format('YYYY-MM-DD'),
      fechaMaxima: moment().format('YYYY-MM-DD'),
      formato: 'input',
      valor:
        dataFiltros.filtros.documento ||
        dataFiltros.filtros.nombres ||
        dataFiltros.filtros.apellidos
          ? ''
          : moment(dataFiltros.filtros.fechaHasta).format('YYYY-MM-DD'),
      estiloPersonalizado: `${
        dataFiltros.filtros.documento ||
        dataFiltros.filtros.nombres ||
        dataFiltros.filtros.apellidos
          ? 'pointer-events-none bg-cendiatra-gris-4'
          : ''
      }`,
    },
    {
      id: 4,
      estilos: 'w-3/12',
      titulo: 'Centro de Costos O Empresa Usuaria',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'empresaUsuaria',
      funcionManejarCambio: (e) => filtrarEmpresas(e),
      funcionOpcionSeleccionada: (e) => manejarEmpresaSeleccionada(e),
      formato: 'autocompletar',
      informacionFiltrada: empresasFiltradas,
      valorCampo: empresaSeleccionada?.nombreEmpresa,
    },
    {
      id: 5,
      estilos: 'w-2/12',
      titulo: 'Estado',
      tipo: 'text',
      funcion: (e) => manejarCambioEnFiltro(e),
      nombre: 'estadoId',
      formato: 'lista',
      opciones: estadosOrden,
      valor: dataFiltros.filtros.estadoId,
    },
  ]

  useEffect(() => {
    obtenerInformacionInicial().catch(console.error)
  }, [actualizarInformacion, dataFiltros.pagina])

  return (
    <div className="w-full flex justify-center items-center ">
      <div className="w-10/12 flex justify-center items-center">
        <ListadoOrdenesYAtenciones
          funcionObtenerDatos={FachadaObtenerOrdenesGestionOrdenes}
          datosTabla={listaOrdenes}
          actualizarDatosTabla={setListaOrdenes}
          filtrosExternos={filtrosExternos}
          funcionFiltrar={filtrarInformacion}
          informacionPaginacion={informacionPaginacion}
          funcionCambiarPagina={manejarCambioDePagina}
          funcionLimpiarFiltros={limpiarFiltros}
        />
      </div>
    </div>
  )
}

export default GestionDeOrdenes
