import {getRestApi} from './ApiAxios'

export const ObtenerMovimientosPorEmpresaId = async (empresaId) => {
  const restApi = await getRestApi()
  return await restApi.get('cuenta-function/ObtenerMovimientosPorEmpresaId', {
    params: {empresaId},
  })
}

export const ObtenerMovimientosUltimoMesPorEmpresaId = async (empresaId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'cuenta-function/ObtenerMovimientosUltimoMesPorEmpresaId',
    {
      params: {empresaId},
    }
  )
}

export const ObtenerInformacionCuentaPorEmpresaId = async (empresaId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'cuenta-function/ObtenerInformacionCuentaPorEmpresaId',
    {
      params: {empresaId},
    }
  )
}
export const ObtenerMovimientosPendientesPorEmpresaId = async (empresaId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'cuenta-function/ObtenerMovimientosPendientesPorEmpresaId',
    {
      params: {empresaId},
    }
  )
}

export const ObtenerReciboDeCaja = async (transaccionId) => {
  const restApi = await getRestApi()
  return await restApi.get('cuenta-function/ObtenerReciboDeCaja', {
    params: {transaccionId},
  })
}
