import {useEffect, useContext} from 'react'
import {TablaGraficas} from '../componentes/Home'
import {Noticias, BarraLateral} from '../componentes/Home'
import {useMsal} from '@azure/msal-react'
import {PoblarCacheComun} from '../cache/servicios-cache'
import {ContextoAplicacion} from '../contexto'
import {
  AgregarEmpresasACache,
  AgregarServiciosACache,
} from '../cache/servicios-cache'
import {useState} from 'react'
import {
  ObtenerInformacionCuentaPorEmpresaId,
  FachadaObtenerEjecucionAtencionesOrdenesMesEmpresaId,
} from '../microservicios'
import useValidacionTransaccionPendiente from '../componentes/usuarioContable/ValidacionTransaccionPendiente'
const Home = () => {
  useValidacionTransaccionPendiente()

  const {accounts} = useMsal()

  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [datosAtenciones, setdatosAtenciones] = useState({})

  const [saldoDisponible, setSaldoDisponible] = useState({})

  const contexto = useContext(ContextoAplicacion)

  useEffect(() => {
    PoblarCacheComun()
    AgregarEmpresasACache()
    AgregarServiciosACache()
  }, [accounts])

  useEffect(() => {
    const consultarDatosAtenciones = async () => {
      try {
        const fecha = new Date()
        const res = await FachadaObtenerEjecucionAtencionesOrdenesMesEmpresaId(
          idEmpresa,
          fecha
        )
        if (res.status === 204) {
          return
        }
        setdatosAtenciones(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    const obtenerInformacionCuentaPorEmpresaId = async () => {
      try {
        const res = await ObtenerInformacionCuentaPorEmpresaId(idEmpresa)
        if (res.status === 204) {
          return
        }
        setSaldoDisponible(res.data)
      } catch (error) {
        console.log(error)
      }
    }

    consultarDatosAtenciones()
    obtenerInformacionCuentaPorEmpresaId()
  }, [])

  return (
    <div className="w-full absolute inset-0 flex flex-wrap justify-center items-end mt-36">
      <BarraLateral
        datosAtenciones={datosAtenciones}
        saldoDisponible={saldoDisponible}
      />
      <div className="w-82% h-auto flex flex-wrap justify-center items-center ">
        <div className="w-full z-40">
          <TablaGraficas nombreEmpresa={'CENCOSUD SUPERMERCADOS'} />
        </div>
        <div className="w-full py-10">
          <Noticias />
        </div>
      </div>
    </div>
  )
}

export default Home
