import {
  BarChart,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

/** Componente genérico para representar una gráfica de barras. */
export const GraficaBarras = ({
  datos,
  barras,
  ejesX,
  ejesY,
  tooltip,
  margins = {
    top: 5,
    right: 10,
    left: 0,
    bottom: 5,
  },
  altura = 310,
}) => {
  return (
    <ResponsiveContainer width="100%" height={altura}>
      <BarChart width={500} height={300} data={datos} margin={margins}>
        <CartesianGrid strokeDasharray="3 3" />
        {tooltip || <Tooltip />}
        {barras}
        {typeof ejesX === 'string' ? <XAxis dataKey={ejesX} /> : ejesX}
        {typeof ejesY === 'string' ? <YAxis dataKey={ejesY} /> : ejesY}
      </BarChart>
    </ResponsiveContainer>
  )
}
