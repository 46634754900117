import React, {useRef} from 'react'
import {CSVLink} from 'react-csv'
import FlechaDescargar from '../../imagenes/flechaDescargar.png'

const ExportarCSVMovimientos = ({
  encabezados,
  obtenerDatos,
  nombreArchivo,
  texto,
}) => {
  const csvLinkEl = useRef()

  const manejarObtenerDatos = async (e) => {
    e.stopPropagation()
    csvLinkEl.current.link.click()
  }

  const csvReport = {
    data: obtenerDatos,
    headers: encabezados,
    filename: nombreArchivo,
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <button
        onClick={(e) => manejarObtenerDatos(e)}
        className="bg-cendiatra flex justify-center items-center h-12 w-36 text-white rounded flex-nowrap p-1"
      >
        <img src={FlechaDescargar} alt="descargar" />
        <span>{texto} </span>
        <CSVLink {...csvReport} ref={csvLinkEl}></CSVLink>
      </button>
    </div>
  )
}

export default ExportarCSVMovimientos
