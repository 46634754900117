import React from 'react'
import {PieChart, Pie, Cell, ResponsiveContainer, Legend} from 'recharts'

const data = [
  {name: 'EXÁMENES INGRESO', value: 500, mensaje: 'Satisfactorio'},
  {name: 'EXÁMENES PERIODICOS', value: 390, mensaje: 'Satisfactorio'},
  {name: 'EXÁMENES RETIRO', value: 300, mensaje: 'Satisfactorio'},
]

const COLORS = ['#3559CB', '#2B9EB3', '#E55D23', '#FF8042']

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const renderLegend = (props) => {
  const {payload} = props
  return (
    <ul>
      {
        <div className="w-full flex justify-center items-center">
          {payload.map((entry, index) => (
            <div
              className="w-2/6 flex flex-wrap justify-start items-center "
              key={`item-${index}`}
            >
              <span
                className="w-full mb-2 text-cendiatra-gris-1 font-helveticaNegrita"
                style={{fontSize: '10px'}}
              >
                {entry.value}
              </span>
              <div
                className="rounded-full h-5 w-5"
                style={{backgroundColor: entry.color}}
              ></div>
              <span
                className="w-20 pl-2 text-cendiatra-gris-1"
                style={{fontSize: '10px'}}
              >
                {entry.payload.mensaje}
              </span>
            </div>
          ))}
        </div>
      }
    </ul>
  )
}

export default function GraficaDeTorta() {
  return (
    <>
      <div className="w-full flex justify-evenly items-center h-10 mb-5">
        <span className="font-helveticaNegrita text-cendiatra-gris-1 text-17px">
          APTOS
        </span>
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart width={400} height={400}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}
