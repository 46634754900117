let config = null

export const obtenerApiUrl = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_API_URL_MICROSERVICIOS
}

export const obtenerNombreAmbiente = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_ENV
}

export const obtenerVersionApp = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_VERSION
}

export const obtenerUrlRedirect = async () => {
  const config = await obtenerConfiguracion()
  return config.REACT_APP_REDIRECT_URI_PORTAL
}

const obtenerConfiguracion = async () => {
  if (config) {
    return config
  }
  const configFile = await fetch('./config.json')
  config = await configFile.json()

  return config
}

export const obtenerFeatureFlagConfig = async () => {
  const config = await obtenerConfiguracion()
  return {
    Key: config.REACT_APP_GROWTH_KEY,
    Host: config.REACT_APP_GROWTH_HOST,
    DevMode: config.REACT_APP_GROWTH_DEVMODE,
  }
}
