import React from 'react'

const CeldaTextoConBoton = ({
  texto,
  mostrarImagen,
  funcion,
  estilos,
  imagen,
}) => {
  return (
    <div className={estilos}>
      <span className="w-auto">{texto}</span>
      <div className="w-8">
        <img
          src={imagen}
          className={`ml-4 h-8 w-8 cursor-pointer ${
            mostrarImagen ? 'z-50 relative' : 'hidden'
          }`}
          alt="imagen"
          onClick={funcion}
        />
      </div>
    </div>
  )
}

export default CeldaTextoConBoton
