import React from 'react'

const CheckboxSeleccionar = React.forwardRef(
  ({indeterminate, ...rest}, ref) => {
    const defaultRef = React.useRef()
    const resolvedRef = ref || defaultRef
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate
    }, [resolvedRef, indeterminate])

    return (
      <>
        <input
          className={
            rest.disabled
              ? 'checkboxEspecialDeshabilitado'
              : 'checkboxEspecial personalizado'
          }
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    )
  }
)

export default CheckboxSeleccionar
