import React from 'react'

const FiltroTexto = ({column: {filterValue, preFilteredRows, setFilter}}) => {
  return (
    <input
      className="flex w-full justify-evenly items-center rounded-md text-cendiatra p-5px"
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined)
      }}
      placeholder={`Buscar..`}
    />
  )
}

export default FiltroTexto
