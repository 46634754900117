import {
  LineChart,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

/** Componente para renderizar la leyenda. */
const renderLegend = ({payload}) => {
  return (
    <div className="w-full flex items-center divide-x">
      {payload.map((entry, index) => (
        <div
          className="w-1/4 flex flex-col flex-wrap justify-center items-center"
          key={`item-${index}`}
        >
          <span
            className="mb-2 text-cendiatra-gris-1 font-helveticaNegrita text-center"
            style={{fontSize: '10px'}}
          >
            {entry.value}
          </span>
          <div
            className="rounded-full h-5 w-5"
            style={{backgroundColor: entry.color}}
          ></div>
        </div>
      ))}
    </div>
  )
}

/** Componente genérico para representar una gráfica líneal */
export const GraficaLineal = ({datos, lineas, ejesX, ejesY}) => {
  return (
    <ResponsiveContainer width="100%" height={310}>
      <LineChart
        width={500}
        height={300}
        data={datos}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend content={renderLegend} />
        {lineas}
        {typeof ejesX === 'string' ? <XAxis dataKey={ejesX} /> : ejesX}
        {typeof ejesY === 'string' ? <YAxis dataKey={ejesY} /> : ejesY}
      </LineChart>
    </ResponsiveContainer>
  )
}
