import React from 'react'
import DescargaMasivaDeConceptos from '../../componentes/DescargaMasiva/DescargaMasivaDeConceptos'
import {TEXTO_VISUAL} from '../../constantes'

const DescargaMasiva = () => {
  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex">
          <div className="w-full flex justify-center items-center">
            <h4 className="text-center text-cendiatra text-3xl font-bold">
              {TEXTO_VISUAL.HOME.NAVEGACION.DESCARGA_MASIVA.toUpperCase()}
            </h4>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-6">
        <DescargaMasivaDeConceptos />
      </div>
    </div>
  )
}

export default DescargaMasiva
