import Dexie from 'dexie'
import {PoblarCacheComun} from '../servicios-cache'

export const dbComun = new Dexie('CendiatraClienteCacheComun')

dbComun.version(1).stores({
  TiposExamen: 'Id, Nombre',
  EstadosOrden: 'Id, Nombre',
  ConceptosOrden: 'Id, Nombre',
  TiposDocumento: 'Id, Nombre',
  TiposUsuario: 'Id, Nombre',
  Sedes: 'Id , Nombre, CiudadId',
  Version: 'Numero, Fecha',
})

dbComun.version(2).stores({
  TiposExamen: 'Id, Nombre',
  Examenes: 'Id, Nombre, Observacion',
  EstadosOrden: 'Id, Nombre',
  ConceptosOrden: 'Id, Nombre',
  TiposDocumento: 'Id, Nombre',
  TiposUsuario: 'Id, Nombre',
  Sedes: 'Id , Nombre, CiudadId',
  Ciudades: 'Id, Nombre',
  Version: 'Numero, Fecha',
})

dbComun
  .version(3)
  .stores({
    TiposExamen: 'Id, Nombre',
    Examenes: 'Id, Nombre, Observacion',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre',
    Version: 'Numero, Fecha',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(4)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre',
    Version: 'Numero, Fecha',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(5)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre',
    Version: 'Numero, Fecha',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(6)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(7)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
    EstadosMovimiento: 'Id, Nombre',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(8)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
    EstadosMovimiento: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(9)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
    EstadosMovimiento: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    Aliados: 'Id, Nombre, CiudadId',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })

dbComun
  .version(10)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
    EstadosMovimiento: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    Aliados: 'Id, Nombre, CiudadId',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })
dbComun
  .version(11)
  .stores({
    TiposExamen: 'Id, Nombre',
    EstadosOrden: 'Id, Nombre',
    ConceptosOrden: 'Id, Nombre',
    ConceptoDeAptitud: 'Id, Nombre',
    TiposDocumento: 'Id, Nombre',
    TiposUsuario: 'Id, Nombre',
    Sedes: 'Id , Nombre, CiudadId, Direccion',
    Ciudades: 'Id, Nombre, DepartamentoId',
    Version: 'Numero, Fecha',
    Departamentos: 'Id, Nombre, CodigoDian',
    EstadosMovimiento: 'Id, Nombre',
    EstadoAtencion: 'Id, Nombre',
    Aliados: 'Id, Nombre, CiudadId',
  })
  .upgrade((trans) => {
    return PoblarCacheComun()
  })
// ..
// Agregar versionamiento en esta sección si se desea hacer cambios en el esquema.
// Todas las versiones deben mantenerse como historial y para dar soporte a todos los clientes y los upgrades que sean necesarios desde cualquier versión vieja.
//
// Ejemplo:
//
// dbComun.version(2).stores({
//     Departamentos: "Id, Nombre",
//     Ciudades: "Id, Nombre, DepartamentoId",
//     Cups: "Id, Codigo, Nombre",
//     Sedes: "Id , Name, CiudadId",
//     Aliados: "Id, Nombre, CiudadId",
//     Version: "Numero, Fecha",
//     NuevaTabla: "Columna1, Columna2"
// }).upgrade(trans => {
//    return PoblarCacheComun(true);
// });
//
// El método PoblarCacheComun también debería ser modificado para agregar la información de la nueva tabla
// ..

dbComun.open().catch(function (err) {
  console.error('Fallo al abrir el cache común: ' + (err.stack || err))
})
