import React, {useEffect, useState} from 'react'
import {useMsal} from '@azure/msal-react'
import {useLocation} from 'react-router-dom'
import {ValidarEstadoTransaccionOtroServicio} from '../../microservicios/OtrosServicios'
import {TEXTO_VISUAL} from '../../constantes'
import EstadosPagoOtrosServicios from '../../componentes/PerfilContado/EstadosPagoOtrosServicios'

const FinalizarRecargaOtrosServicios = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const pmtId = searchParams.get('pmtId')

  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [ip, setIp] = useState('')
  const [ipReady, setIpReady] = useState(false)
  const [transaccionEstadoSaldo, setTransaccionEstadoSaldo] = useState({})

  const obtenerDireccionIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      setIp(data.ip)
      setIpReady(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    obtenerDireccionIP()
  }, [])

  useEffect(() => {
    if (ipReady) {
      const estadoTransaccion = async () => {
        const transaccionId = sessionStorage.getItem('transaccionId')
        try {
          const res = await ValidarEstadoTransaccionOtroServicio({
            transaccionId: transaccionId,
            ip: ip,
          })
          if (res.data) {
            setTransaccionEstadoSaldo(res.data)
            sessionStorage.removeItem('transaccionId')
          }
        } catch (error) {
          console.log(error)
        }
      }

      estadoTransaccion()
    }
  }, [idEmpresa, pmtId, ip, ipReady])

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex-col">
          <div className="w-full flex-col justify-center items-center">
            <>
              <h4 className="w-full text-center text-cendiatra text-2xl font-bold">
                {TEXTO_VISUAL.OTROS_SERVICIOS.TITULO}
              </h4>
              <span className="w-full flex justify-center items-center text-cendiatra text-xl font-bold">
                {TEXTO_VISUAL.OTROS_SERVICIOS.SUBTITULO}
              </span>
            </>
          </div>

          <EstadosPagoOtrosServicios
            transaccionEstadoSaldo={transaccionEstadoSaldo}
          />
        </div>
      </div>
    </div>
  )
}

export default FinalizarRecargaOtrosServicios
