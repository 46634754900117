const TablaServicios = ({data, seleccionarServicio, seleccionados}) => {
  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <table
        className="w-full  border-cendiatra"
        style={{
          borderCollapse: 'collapse',
          borderRadius: '2em',
        }}
      >
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-14 rounded-10px"
          style={{lineHeight: '16px'}}
        >
          <th>SERVICIOS CONTRATADOS DISPONIBLES PARA ORDENAR</th>
        </thead>
        <tbody
          className="bg-grey-light flex flex-col overflow-y-scroll w-full border border-cendiatra rounded"
          style={{height: '280px'}}
        >
          {data?.map((item, key) => {
            return (
              <tr
                className="w-full text-cendiatra-gris-1 rounded-2xl flex text-center text-13px px-5 py-2 border"
                key={key}
              >
                <td className="text-cendiatra-gris-1 flex w-full">
                  <div className="pr-4">
                    <input
                      type="checkbox"
                      className="checkboxEspecial personalizado"
                      onClick={() => seleccionarServicio(item)}
                      disabled={seleccionados.find(
                        (seleccionado) =>
                          seleccionado?.Id === item?.Id &&
                          seleccionado.Tipo === item.Tipo
                      )}
                      checked={seleccionados.find(
                        (seleccionado) =>
                          seleccionado?.Id === item?.Id &&
                          seleccionado.Tipo === item.Tipo
                      )}
                    />
                  </div>
                  <div className="w-11/12 text-left">
                    {item.Nombre?.toUpperCase() ?? ''}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaServicios
