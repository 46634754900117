import React, {useState} from 'react'
import Input from '../inputs/Input'
import BotonSimple from '../Botones/BotonSimple'
import ListaDesplegable from '../inputs/ListaDesplegable'
import BotonConImagen from '../Botones/BotonConImagen'
import FlechaColorBlanco from '../../imagenes/flechaBlanca.png'
import CampoFiltrar from '../inputs/CampoFiltrar'

const ComponenteConjuntoDeFiltros = ({
  filtrosPorCrear,
  funcionBoton,
  textoFiltrosPrimeraLinea,
  textoFiltrosSegundaLinea,
  mostrarTextosEnFiltros,
}) => {
  const [abrirMenuFiltros, setAbrirMenuFiltros] = useState(false)

  const abrirMenu = () => {
    setAbrirMenuFiltros(!abrirMenuFiltros)
  }

  const manejarFormatoFiltro = (filtro) => {
    switch (filtro.formato) {
      case 'input':
        return (
          <Input
            tipo={filtro?.tipo}
            estilosContenedor={'my-2 w-full flex justify-center items-center'}
            estilosInput={`text-cendiatra-gris-3 rounded-lg h-8 w-11/12 ${filtro?.estiloPersonalizado}`}
            onChange={filtro?.funcion}
            fechaMaxima={filtro?.fechaMaxima ?? null}
            fechaMinima={filtro?.fechaMinima ?? null}
            placeholder={'Buscar'}
            valor={filtro.valor}
            id={filtro.id}
            nombre={filtro.nombre}
          />
        )
      case 'lista':
        return (
          <ListaDesplegable
            onChange={filtro?.funcion}
            estilosContenedor={'my-2 w-full flex justify-center items-center'}
            estilosLista={`text-cendiatra-gris-3 rounded-lg h-8 w-11/12 ${filtro?.estiloPersonalizado}`}
            opciones={filtro?.opciones}
            nombre={filtro?.nombre}
            valor={filtro?.valor}
            deshabilitado={filtro?.deshabilitado}
          />
        )
      case 'autocompletar':
        return (
          <CampoFiltrar
            estilosPersonalizados={
              'my-2 w-full flex justify-center items-center'
            }
            textoTitulo={''}
            estilosInput={`text-cendiatra-gris-3 rounded-lg h-8 w-11/12 ${filtro?.estiloPersonalizado}`}
            placeholder={'Descripción (Autocompletar)'}
            tipo={filtro?.tipo}
            valorDelCampoFiltro={filtro?.valorCampo}
            desactivarOtroFiltro={''}
            informacionFiltrada={filtro?.informacionFiltrada}
            handleChange={filtro?.funcionManejarCambio}
            handleOptionChange={filtro?.funcionOpcionSeleccionada}
          />
        )
    }
  }
  return (
    <>
      <div
        className={
          abrirMenuFiltros && mostrarTextosEnFiltros
            ? 'flex w-full items-center justify-between'
            : 'hidden'
        }
      >
        <div className="w-4/12 flex justify-center items-center text-cendiatra-verde-2 text-lg text-center">
          {textoFiltrosPrimeraLinea}
        </div>
        <div className="w-5/12 flex justify-center items-center text-cendiatra-verde-2 text-lg text-center mt-1">
          {textoFiltrosSegundaLinea}
        </div>
      </div>
      <div
        className={
          abrirMenuFiltros
            ? 'w-full justify-start h-24 items-center my-5 flex flex-nowrap bg-cendiatra p-1 rounded-2xl transition-color duration-100'
            : 'w-1/12 justify-center h-12 items-center my-5 flex flex-nowrap bg-cendiatra p-1 rounded-2xl transition-color duration-100'
        }
      >
        <div
          className={`w-95% ${
            abrirMenuFiltros ? 'flex' : 'hidden'
          } justify-start items-center`}
        >
          {filtrosPorCrear.map((filtro) => (
            <div
              id={filtro.id}
              className={` ${filtro?.estilos} h-20 flex flex-wrap bg-cendiatra-verde-3 justify-center items-center text-center rounded-xl mr-2`}
            >
              <span className="w-full text-white mt-3 text-14px">
                {filtro?.titulo}
              </span>
              <div className="w-full flex justify-center items-center -mt-5">
                {manejarFormatoFiltro(filtro)}
              </div>
            </div>
          ))}
          <BotonSimple
            texto={'Buscar'}
            estilosBoton={
              'w-28 h-10 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg border border-white'
            }
            funcion={funcionBoton}
            tipoDeBoton={'button'}
          />
        </div>
        <div
          className={`${
            abrirMenuFiltros ? 'w-5%' : 'w-full'
          } flex justify-center items-center `}
        >
          <div
            className={`w-8/12 ${
              abrirMenuFiltros ? 'hidden' : 'flex'
            } justify-center items-center`}
          >
            <span className="text-white text-xl">Filtros</span>
          </div>
          <div
            className={`${
              abrirMenuFiltros ? 'w-full mt-1' : 'w-4/12 mb-1'
            } flex justify-center items-center`}
          >
            <BotonConImagen
              estilosContenedor={'w-full z-50 '}
              imagen={FlechaColorBlanco}
              textoAlternativo={'imagen'}
              funcionAEjecutar={abrirMenu}
              estilosImagen={'h-10 w-10'}
              rotacion={abrirMenuFiltros ? '0' : '180'}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ComponenteConjuntoDeFiltros
