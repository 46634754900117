export const ESTADOS_SERVICIO_ATENCION_ID = {
  1: 'PENDIENTE',
}

export const ESTADOS_SERVICIO_ATENCION = {
  PENDIENTE: 'PENDIENTE',
}

export function servicioAtencionPendientePorAtender(estadoServicioId) {
  const estado = ESTADOS_SERVICIO_ATENCION_ID[estadoServicioId]

  return estado === ESTADOS_SERVICIO_ATENCION.PENDIENTE
}
