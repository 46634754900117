import {getRestApi} from './ApiAxios'

export const ObtenerPaquetes = async (fechaUltimaActualizacion) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'servicio-function/ObtenerPaquetes?fecha=' + fechaUltimaActualizacion
  )
}

export const ObtenerServicios = async (fechaUltimaActualizacion) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'servicio-function/ObtenerServicios?fecha=' + fechaUltimaActualizacion
  )
}

export const ObtenerServicioPorId = async (id) => {
  const restApi = await getRestApi()
  return await restApi.get('servicio-function/ObtenerServicioPorId?id=' + id)
}
