import React from 'react'
import {useForm} from 'react-hook-form'
import {useMsal} from '@azure/msal-react'
import CENDIATRA from '../imagenes/cendiatraLogo.png'
import {getLoginContext} from '../msalService'

const Landing = () => {
  const {instance, accounts} = useMsal()
  const {handleSubmit} = useForm()

  const onSubmit = () => {
    instance
      .loginRedirect(getLoginContext())
      .then((res) => {
        instance
          .acquireTokenSilent({
            ...getLoginContext(),
            account: accounts[0],
          })
          .then((response) => {
            // callMsGraph(response.accessToken).then(response => setGraphData(response));
            sessionStorage.setItem('token', response.accessToken)
          })
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <>
      <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-fondoAplicacion bg-cover">
        <div className=" sm:w-3/5 md:w-2/5 lg:w-1/3 shadow overflow-hidden space-y-8 border border-cendiatra border-opacity-25  rounded-lg  bg-white rounded-t-3xl rounded-b-3xl">
          <div className="flex flex-wrap h-64 justify-center items-center w-full bg-fondoLogin bg-cover bg-center  rounded-t-3xl rounded-b-3xl">
            <div>
              <div className="w-full flex justify-center items-center bg-white rounded-full border-white p-2">
                <img className=" h-20 w-auto " src={CENDIATRA} alt="Workflow" />
              </div>
              <div
                className="w-full text-center px-2"
                style={{marginTop: '-1px'}}
              >
                <p className=" font-sans text-white text-xl mt-3 font-bold">
                  Portal cliente
                </p>
              </div>
            </div>
          </div>

          <form
            className=" space-y-5 "
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
          >
            <div className="flex justify-center">
              <button
                type="submit"
                className="items-center mb-10 group relative w-32 h-10 flex justify-center py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-center bg-fondoBoton bg-cover bg-no-repeat  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cendiatra"
              >
                Autenticarse
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Landing
