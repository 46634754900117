import React, {useEffect, useState} from 'react'
import BotonSimple from '../Botones/BotonSimple'

const PaginacionConBackend = ({
  totalPaginas,
  paginaActual,
  funcionIrAPagina,
  maximoDePaginasparaMostrar,
}) => {
  const [numerosParaMostrar, setNumerosParaMostrar] = useState([])
  const [conjuntoNumerosPaginacion, setConjuntoNumerosPaginacion] = useState(1)
  const [arrayDeNumerosInicial, setArrayDeNumerosInicial] = useState([])

  useEffect(() => {
    setArrayDeNumerosInicial(
      Array.from({length: totalPaginas}, (_, index) => index + 1)
    )
  }, [totalPaginas])

  useEffect(() => {
    const crearArrayNumeroDePaginas = () => {
      const inicio =
        (conjuntoNumerosPaginacion - 1) * maximoDePaginasparaMostrar
      const numerosAMostrar = arrayDeNumerosInicial.slice(
        inicio,
        inicio + maximoDePaginasparaMostrar
      )

      setNumerosParaMostrar(numerosAMostrar)
    }
    crearArrayNumeroDePaginas()
  }, [arrayDeNumerosInicial, conjuntoNumerosPaginacion])

  const retrocederEnLista = () => {
    if (conjuntoNumerosPaginacion > 1) {
      setConjuntoNumerosPaginacion(conjuntoNumerosPaginacion - 1)
      const inicio =
        (conjuntoNumerosPaginacion - 2) * maximoDePaginasparaMostrar
      const numerosAMostrar = arrayDeNumerosInicial.slice(
        inicio,
        inicio + maximoDePaginasparaMostrar
      )
      setNumerosParaMostrar(numerosAMostrar)
    }
  }
  const avanzarEnLista = () => {
    const totalPaginas = Math.ceil(
      arrayDeNumerosInicial.length / maximoDePaginasparaMostrar
    )
    if (conjuntoNumerosPaginacion < totalPaginas) {
      setConjuntoNumerosPaginacion(conjuntoNumerosPaginacion + 1)
    }
  }

  const irAlInicio = () => {
    setConjuntoNumerosPaginacion(1)
  }

  const irAlFinal = () => {
    const totalPaginas = Math.ceil(
      arrayDeNumerosInicial.length / maximoDePaginasparaMostrar
    )
    setConjuntoNumerosPaginacion(totalPaginas)
    const inicio = (totalPaginas - 1) * maximoDePaginasparaMostrar
    const numerosAMostrar = arrayDeNumerosInicial.slice(
      inicio,
      inicio + maximoDePaginasparaMostrar
    )
    setNumerosParaMostrar(numerosAMostrar)
  }
  return (
    <div className="w-full flex justify-center m-5 h-10 ">
      <div className="flex">
        {/* <button
          className="text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3"
          onClick={irAlInicio}
        >
          {'«'}
        </button>{' '} */}
        <BotonSimple
          texto={'«'}
          estilosBoton={
            'text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3'
          }
          tipoDeBoton={'button'}
          funcion={irAlInicio}
        />
        <BotonSimple
          texto={'‹'}
          estilosBoton={
            'text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3 mr-3'
          }
          tipoDeBoton={'button'}
          funcion={retrocederEnLista}
        />
        {/* <button
          className="text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3 mr-3"
          onClick={retrocederEnLista}
        >
          {'‹'}
        </button>{' '} */}
        {numerosParaMostrar?.map((elemento) => (
          <div
            key={elemento}
            className={
              elemento === paginaActual
                ? 'z-10 bg-cendiatra border-cendiatra text-white relative inline-flex items-center px-4 border text-13px font-medium cursor-pointer rounded-full '
                : 'text-cendiatra-gris-1 hover:text-cendiatra-gris-3 relative inline-flex items-center px-4 border text-sm font-medium cursor-pointer rounded-full'
            }
            onClick={() => funcionIrAPagina(elemento)}
          >
            {elemento}
          </div>
        ))}
        <BotonSimple
          texto={'›'}
          estilosBoton={
            'text-5xl text-cendiatra-gris-1 relative inline-flex items-center pb-3 ml-3'
          }
          tipoDeBoton={'button'}
          funcion={avanzarEnLista}
        />
        {/* <button
          className="text-5xl text-cendiatra-gris-1 relative inline-flex items-center pb-3 ml-3"
          onClick={avanzarEnLista}
        >
          {'›'}
        </button>{' '} */}
        <BotonSimple
          texto={'»'}
          estilosBoton={
            'text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3'
          }
          tipoDeBoton={'button'}
          funcion={irAlFinal}
        />
        {/* <button
          className="text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3"
          onClick={irAlFinal}
        >
          {'»'}
        </button>{' '} */}
      </div>
    </div>
  )
}

export default PaginacionConBackend
