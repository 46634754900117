import React, {useContext} from 'react'
import {useForm, Controller} from 'react-hook-form'
import {Input} from '../componentes/inputs'
import CambiarContraseña from '../imagenes/cambiarContrasena.svg'
import {BotonSimple} from '../componentes/Botones'
import {ModalConfirmacionEstandar} from '../componentes/Modales'
import {ContextoAplicacion} from '../contexto'
import {useHistory} from 'react-router-dom'

const CambioContraseña = () => {
  const contextoAplicacion = useContext(ContextoAplicacion)

  const history = useHistory()

  const {
    trigger,
    setValue,
    control,
    formState: {errors, isValid},
    setError,
    clearErrors,
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onChange',
  })

  const manejarCerrarModalConfirmacion = () => {
    contextoAplicacion.setModal({abierto: false, botones: []})
    history.push('/home')
  }

  const subirformulario = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'CONTRASEÑA CAMBIADA EXITOSAMENTE',
      contenido: <ModalConfirmacionEstandar />,
      botones: [
        {
          nombre: 'Finalizar',
          click: manejarCerrarModalConfirmacion,
        },
      ],
    })
  }

  const validarContraseña = () => {
    let comparacion = getValues()
    if (
      comparacion.nuevaContrasena != undefined &&
      comparacion.confirmarContrasena != undefined
    ) {
      if (comparacion.nuevaContrasena === comparacion.confirmarContrasena) {
        return true
      } else {
        return false
      }
    }
  }

  return (
    <form className="w-full mt-5 flex justify-center items-center flex-wrap mb-20">
      <div className="w-full flex flex-wrap justify-center items-center ">
        <span className="w-full text-cendiatra text-3xl font-bold text-center my-5">
          CAMBIO DE CONTRASEÑA
        </span>
        <img
          className="w-20 my-10"
          src={CambiarContraseña}
          alt="cambiar contraseña"
        />
      </div>

      <div className="w-10/12 flex justify-evenly  items-center ">
        <Controller
          name="contrasenaActual"
          control={control}
          rules={{
            required: true,
          }}
          render={({field: {onChange}}) => (
            <Input
              estilosContenedor={'w-3/12'}
              onChange={onChange}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'contrasenaActual'
                )
                  ? 'appearance-none rounded relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                  : 'appearance-none rounded relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
              }
              tipo={'password'}
              titulo={'Contraseña actual*'}
              placeholder={'Descripción'}
            />
          )}
        />
      </div>
      <div className="w-10/12 flex justify-evenly  items-center ">
        <Controller
          name="nuevaContrasena"
          control={control}
          rules={{
            required: true,
            validate: validarContraseña,
            pattern:
              /^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
          }}
          render={({field: {onChange}}) => (
            <Input
              onChange={onChange}
              estilosContenedor={'w-3/12'}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'nuevaContrasena'
                )
                  ? 'appearance-none rounded relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                  : 'appearance-none rounded relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
              }
              tipo={'password'}
              titulo={'Nueva Contraseña*'}
              placeholder={'Descripción'}
            />
          )}
        />
      </div>
      <div className="w-10/12 flex justify-evenly  items-center ">
        <Controller
          name="confirmarContrasena"
          control={control}
          rules={{
            required: true,
            validate: validarContraseña,
            pattern:
              /^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/,
          }}
          render={({field: {onChange}}) => (
            <Input
              estilosContenedor={'w-3/12'}
              onChange={onChange}
              estilosInput={
                Object.keys(errors).find(
                  (element) => element === 'confirmarContrasena'
                )
                  ? 'appearance-none rounded relative block w-full px-3 py-2 border border-red-600 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-red-600 focus:z-10 sm:text-sm my-2'
                  : 'appearance-none rounded relative block w-full px-3 py-2 border border-cendiatra placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm my-2'
              }
              tipo={'password'}
              titulo={'Confirmar nueva contraseña'}
              placeholder={'Descripción'}
            />
          )}
        />
      </div>
      <div className="w-full flex justify-center items-center mt-10">
        <span className="text-red-500 ">
          {errors.nuevaContrasena?.type === 'pattern' ||
          errors.confirmarContrasena?.type === 'pattern'
            ? 'La contraseña debe tener minimo 8 caracteres, debe incluir un caracter especial, una mayúscula,minúscula y números'
            : null}
          {errors.nuevaContrasena?.type === 'validate' ||
          errors.confirmarContrasena?.type === 'validate'
            ? 'Las contraseñas no coinciden'
            : null}
        </span>
      </div>
      <div className="w-full flex justify-center items-center mt-10">
        <BotonSimple
          texto={'Guardar'}
          estilosBoton={
            'w-36 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg'
          }
          funcion={subirformulario}
          tipoDeBoton={'button'}
          deshabilitado={false}
        />
      </div>
    </form>
  )
}

export default CambioContraseña
