const TablaVisualizacionObservaciones = ({observaciones = [], titulo = ''}) => {
  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <label className="w-full text-start block text-xl text-17px text-cendiatra my-5">
        {titulo}
      </label>
      <table className="w-full border">
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-14 rounded-10px"
          style={{lineHeight: '16px'}}
        >
          <th>OBSERVACIONES DE PREPARACIÓN</th>
        </thead>
        <tbody
          className="bg-grey-light flex flex-col overflow-y-scroll w-full border border-cendiatra rounded"
          style={{height: '250px'}}
        >
          {observaciones?.map((item, key) => {
            return (
              <tr
                className="w-full text-cendiatra-gris-1 rounded-2xl flex text-center text-13px px-5 py-2 border"
                key={key}
              >
                <td className="text-cendiatra-gris-1 flex w-full">
                  <div className="w-12/12 text-left text-cendiatra text-bold">
                    {item?.Observacion ?? ''}
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaVisualizacionObservaciones
