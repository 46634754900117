import React from 'react'

const AreaDeTexto = ({
  estilosContenedor,
  estilosAreaDeTexto,
  titulo,
  deshabilitado,
  placeholder,
  valor,
  cambioValor,
  id,
  rows = 4,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px leading-15px text-cendiatra-verde-2 my-5px">
        {titulo}
      </label>
      <textarea
        className={estilosAreaDeTexto}
        disabled={deshabilitado}
        placeholder={placeholder}
        onChange={cambioValor}
        value={valor}
        id={id}
        rows={rows}
      />
    </div>
  )
}

export default AreaDeTexto
