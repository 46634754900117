import moment from 'moment'

export const validarRolAsignado = (objeto, rol) => {
  if (objeto === undefined) {
    return true
  }
  if (Array.isArray(objeto)) {
    return objeto.filter((elemento) => elemento === rol)
  } else {
    return objeto === rol
  }
}

export const simplificarTexto = (texto) => {
  return texto?.split(' ')?.pop()
}

export const ordenarListaOrdenes = (array) => {
  if (array) {
    return array.sort((a, b) => a.estadoOrdenId - b.estadoOrdenId)
  }
  return []
}

export const agregarFormatoFecha = (e) => {
  if (e?.target?.name === 'fechaDesde') {
    return e.target.value === ''
      ? moment().format('YYYY-MM-DD 00:00:00')
      : moment(e.target.value).format('YYYY-MM-DD 00:00:00')
  } else if (e?.target?.name === 'fechaHasta') {
    if (
      moment(e.target.value).format('YYYY-MM-DD') ===
      moment().format('YYYY-MM-DD')
    ) {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    }
    return e.target.value === ''
      ? moment().format('YYYY-MM-DD HH:mm:ss')
      : moment(e.target.value).format('YYYY-MM-DD 23:59:59')
  }
  return e.target.value
}
