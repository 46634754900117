/** Componente que representa el contenedor de una gráfica. */
export const ContenedorCendiatra = ({
  clasesAdicionales = 'w-1/3',
  children,
}) => {
  let clasesCss =
    'flex flex-wrap justify-center items-center text-xs bg-cendiatra-gris-5 p-2 rounded-lg'

  if (typeof clasesAdicionales === 'string') {
    clasesCss = `${clasesCss} ${clasesAdicionales}`
  }

  return <div className={clasesCss}>{children}</div>
}
