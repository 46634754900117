import {getRestApi} from './ApiAxios'

export const ObtenerUsuarioPorId = async (usuarioId) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'usuario-function/ObtenerUsuarioPorId?usuarioId=' + usuarioId
  )
}

export const ObtenerUsuarioPorTipoDocumentoYDocumento = async (
  numeroDocumento,
  tipoDocumento
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'usuario-function/ObtenerUsuarioPorTipoDocumentoYDocumento',
    {params: {numeroDocumento, tipoDocumento}}
  )
}
