import React from 'react'
import IconoAlerta from '../../imagenes/alerta.svg'

const AlertaDinamica = ({mostrarAlerta, mensaje}) => {
  return (
    <div
      className={`w-full bg-cendiatra-gris-5 ${
        mostrarAlerta ? 'flex' : 'hidden'
      } justify-start items-center mt-2 py-2  rounded-lg `}
    >
      <div className="w-2/12 flex justify-center items-center">
        <img src={IconoAlerta} alt="alerta" />
      </div>
      <div className="w-10/12">
        <span className="text-cendiatra-rojo-1 w-full">{mensaje}</span>
      </div>
    </div>
  )
}

export default AlertaDinamica
