import TablaServicios from './TablaServicios'
import TablaServiciosSeleccionados from './TablaServiciosSeleccionados'

import {ReactComponent as FlechaSiguiente} from '../../imagenes/flechaTrazado.svg'
import {useEffect} from 'react'

function SeleccionServicios({
  serviciosPaquetes,
  seleccionarServicio,
  serviciosSeleccionados,
  quitarServicioSeleccionado,
}) {
  return (
    <div className="w-full flex flex-wrap justify-center">
      <div className="w-5/12">
        <TablaServicios
          data={serviciosPaquetes}
          seleccionarServicio={seleccionarServicio}
          seleccionados={serviciosSeleccionados}
        />
      </div>
      <div className="w-1/12 items-center flex">
        <FlechaSiguiente className="w-full bg-white" />
      </div>
      <div className="w-5/12">
        <TablaServiciosSeleccionados
          serviciosSeleccionados={serviciosSeleccionados}
          quitarServicioSeleccionado={quitarServicioSeleccionado}
        />
      </div>
    </div>
  )
}

export default SeleccionServicios
