import Dexie from 'dexie'
import {
  ObtenerCiudades,
  ObtenerReferencias,
  ObtenerSedes,
  ObtenerDepartamentos,
  obtenerAliados,
} from '../../microservicios'
import {dbComun} from '../bases-datos'

export const PoblarCacheComun = async () => {
  await dbComun.open().then(async () => {
    await dbComun.table('Version').clear()
    await dbComun.TiposExamen.clear()
    await dbComun.EstadosOrden.clear()
    await dbComun.ConceptosOrden.clear()
    await dbComun.ConceptoDeAptitud.clear()
    await dbComun.TiposDocumento.clear()
    await dbComun.TiposUsuario.clear()
    await dbComun.Sedes.clear()
    await dbComun.Ciudades.clear()
    await dbComun.Departamentos.clear()
    await dbComun.EstadosMovimiento.clear()
    await dbComun.EstadoAtencion.clear()
    await dbComun.Aliados.clear()

    const valoresReferencia = await ObtenerReferencias(
      'TipoDocumento,EstadoOrden,TipoExamen,ConceptoOrden,ConceptoDeAptitud,TipoUsuario,EstadoMovimiento,EstadoAtencion'
    )

    //Agregar version
    dbComun
      .table('Version')
      .put({
        Numero: valoresReferencia.data.version,
        Fecha: valoresReferencia.data.fechaVersion,
      })
      .catch(Dexie.DataError, function (e) {
        console.error(
          'Error al agregar la versión al caché común: ' + (e.stack || e)
        )
      })

    const valoresReferenciaAgrupados =
      valoresReferencia.data.listaReferencia.reduce(
        (grupoReferencia, elemento) => {
          const grupo = elemento.tipo
          grupoReferencia[grupo] = grupoReferencia[grupo] ?? []
          grupoReferencia[grupo].push(elemento)
          return grupoReferencia
        },
        {}
      )

    //Agregar tipos de examen
    dbComun.TiposExamen.bulkAdd(
      valoresReferenciaAgrupados['TipoExamen'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar estados de orden
    dbComun.EstadosOrden.bulkAdd(
      valoresReferenciaAgrupados['EstadoOrden'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar conceptos de orden
    dbComun.ConceptosOrden.bulkAdd(
      valoresReferenciaAgrupados['ConceptoOrden'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar conceptos de aptitud
    dbComun.ConceptoDeAptitud.bulkAdd(
      valoresReferenciaAgrupados['ConceptoDeAptitud'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar tipos de documento
    dbComun.TiposDocumento.bulkAdd(
      valoresReferenciaAgrupados['TipoDocumento'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar tipos de usuario
    dbComun.TiposUsuario.bulkAdd(
      valoresReferenciaAgrupados['TipoUsuario'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar estados movimiento
    dbComun.EstadosMovimiento.bulkAdd(
      valoresReferenciaAgrupados['EstadoMovimiento'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar estados Atenciones
    dbComun.EstadoAtencion.bulkAdd(
      valoresReferenciaAgrupados['EstadoAtencion'].map((x) => ({
        Id: x.identificacion,
        Nombre: x.nombre,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar sedes
    const sedes = await ObtenerSedes()

    dbComun.Sedes.bulkAdd(
      sedes.data.map((x) => ({
        Id: x.id,
        Nombre: x.nombre,
        CiudadId: x.ciudadId,
        Direccion: x.direccion,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    //Agregar Ciudades
    const ciudades = await ObtenerCiudades()

    dbComun.Ciudades.bulkAdd(
      ciudades.data.map((x) => ({
        Id: x.id,
        Nombre: x.nombre,
        DepartamentoId: x.departamentoId,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar ciudades al caché común: ' + (e.stack || e)
      )
    })

    //Agregar Departamento
    const departamentos = await ObtenerDepartamentos()
    dbComun.Departamentos.bulkAdd(
      departamentos.data.map((x) => ({
        Id: x.id,
        Nombre: x.nombre,
        CodigoDian: x.codigoDIAN,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar departamentos al caché común: ' + (e.stack || e)
      )
    })

    const aliados = await obtenerAliados()
    dbComun.Aliados.bulkAdd(
      aliados.data.map((x) => ({
        Id: x.id,
        Nombre: x.nombre,
        CiudadId: x.ciudadId,
      }))
    ).catch(Dexie.BulkError, function (e) {
      console.error(
        'Error al agregar aliados al caché común: ' + (e.stack || e)
      )
    })
  })
}
