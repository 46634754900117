import React from 'react'

const ImagenEstandar = ({imagen, texto, estilosPersonalizados}) => {
  return (
    <div
      key={texto}
      className={`flex justify-center items-center ${estilosPersonalizados}`}
    >
      <img className="w-full h-full" src={imagen} alt={texto} />
    </div>
  )
}

export default ImagenEstandar
