import React, {useState, createContext} from 'react'
import moment from 'moment'

export const ContextoRecargarSaldo = createContext('')

export const RecargarSaldoConContexto = ({children}) => {
  const [formularioRecargarSaldo, setFormularioRecargarSaldo] = useState({
    fecha: moment().format('YYYY-MM-DD'),
    identificacionCliente: '',
    valorRecarga: '',
    idBanco: '-1',
    detalle: '',
  })

  const [dataPdf, setDataPdf] = useState({
    documento: '',
    nombre: '',
    direccion: '',
    telefono: '',
  })

  return (
    <ContextoRecargarSaldo.Provider
      value={{
        formularioRecargarSaldo: formularioRecargarSaldo,
        setFormularioRecargarSaldo: setFormularioRecargarSaldo,
        dataPdf: dataPdf,
        setDataPdf: setDataPdf,
      }}
    >
      {children}
    </ContextoRecargarSaldo.Provider>
  )
}
