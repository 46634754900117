import {dbCliente} from '../bases-datos'
import Dexie from 'dexie'
import {ObtenerEmpresasDB} from '../../microservicios'

export const AgregarEmpresasACache = async () => {
  await dbCliente.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbCliente.table('FechasActualizacion').get({Tabla: 'Empresas'})
    )?.FechaUltimaActualizacion
    await ObtenerEmpresasDB(fechaUltimaActualizacion).then((res) => {
      if (res.data) {
        dbCliente.Empresas.bulkPut(
          res.data.map((empresa) => ({
            Id: empresa.id,
            CodigoInterno: empresa.codigoInterno,
            Nit: empresa.nit,
            VerificacionNit: empresa.verificacionNit,
            Nombre: empresa.nombre,
            EstaActiva: empresa.estaActiva,
            EjecutivoComercial: empresa.ejecutivoComercial,
            EmpresaPadreId: empresa.empresaPadreId,
            Observaciones: empresa.observaciones,
            TipoFacturacion: empresa.tipoFacturacion,
            Telefono: empresa.telefono,
            Direccion: empresa.direccion,
          }))
        ).catch(Dexie.BulkError, function (e) {
          console.error(
            'Error al agregar empresas al caché comercial: ' + (e.stack || e)
          )
        })
        dbCliente.FechasActualizacion.put({
          Tabla: 'Empresas',
          FechaUltimaActualizacion: res.headers.tiemposervidor,
        })
      }
    })
  })
}

export const ObtenerEmpresas = async () => {
  return Promise.resolve(
    dbCliente
      .table('Empresas')
      .filter(
        ({EmpresaPadreId}) => EmpresaPadreId == null || EmpresaPadreId == 0
      )
      .toArray()
  )
}

export const ObtenerEmpresasHijasPorIdPadre = async (idPadre) => {
  let empresasPadre = await dbCliente.table('Empresas').toArray()
  let empresas = []
  try {
    empresas = (
      await dbCliente
        .table('Empresas')
        .filter(({EmpresaPadreId}) => EmpresaPadreId == idPadre)
        .toArray()
    ).map((empresaHija) => {
      return {
        id: empresaHija?.Id,
        nit: empresaHija?.Nit,
        razonSocial: empresaHija?.Nombre ?? '',
        empresaPadre:
          empresasPadre?.find((x) => x.Id == empresaHija.EmpresaPadreId)
            ?.Nombre ?? null,
        activa: empresaHija?.EstaActiva == true ? 'ACTIVA' : 'INACTIVA',
        observaciones: empresaHija?.Observaciones,
      }
    })
  } catch (error) {
    console.log(error)
  }
  return Promise.resolve(empresas)
}

export const ObtenerEmpresaPadreYempresasHijas = async (idPadre, nombre) => {
  let empresas = []
  try {
    empresas = (
      await dbCliente
        .table('Empresas')
        .filter(
          ({EmpresaPadreId, Id, Nombre, Nit}) =>
            (EmpresaPadreId === parseInt(idPadre) ||
              Id === parseInt(idPadre)) &&
            (Nombre.toUpperCase().includes(nombre.toUpperCase()) ||
              Nit.includes(nombre))
        )
        .toArray()
    ).map((empresaHija) => {
      return {
        Id: empresaHija?.Id,
        Nit: empresaHija?.Nit,
        Nombre: `${empresaHija?.Nit} ${'-'} ${empresaHija?.Nombre}` ?? '',
        Activa: empresaHija?.EstaActiva == true ? 'ACTIVA' : 'INACTIVA',
        Observaciones: empresaHija?.Observaciones,
      }
    })
  } catch (error) {
    console.log(error)
  }
  return Promise.resolve(empresas)
}

export const ObtenerEmpresasYEmpresasHijasEnLista = async () => {
  const lista = []
  const empresas = await ObtenerEmpresas()
  lista.push(...empresas)
  for (const empresa of empresas) {
    const empresasHija = await ObtenerEmpresasHijasPorIdPadre(empresa.Id)
    lista.push(...empresasHija)
  }

  return lista
}
