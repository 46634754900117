import React from 'react'

const ImagenFondo = ({url, estilos}) => {
  return (
    <div className="h-28 w-full flex justify-center items-center">
      <span
        className={`flex  flex-wrap  justify-center rounded-lg h-full bg-contain bg-no-repeat bg-center ${estilos}`}
        style={{backgroundImage: `url(${url})`}}
      ></span>
    </div>
  )
}

export default ImagenFondo
