import React from 'react'

const CeldaConBoton = ({
  funcion,
  imagen,
  deshabilitado = false,
  estilosImagen = 'w-8 h-8',
}) => {
  return (
    <div
      className={`w-full flex justify-center items-center ${
        !deshabilitado ? 'pointer-events-none' : 'cursor-pointer'
      }`}
      onClick={funcion}
    >
      <img className={estilosImagen} src={imagen} alt="Agregar" />
    </div>
  )
}

export default CeldaConBoton
