import {dbComun} from '../bases-datos'

export const ObtenerTodasLasCiudades = async () => {
  return Promise.resolve(dbComun.table('Ciudades').toArray())
}

export const ObtenerCiudadesPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Ciudades')
      .filter(({Nombre}) => Nombre.toUpperCase().includes(nombre.toUpperCase()))
      .toArray()
  )
}

export const ObtenerCiudadPorNombre = async (nombre) => {
  return Promise.resolve(dbComun.table('Ciudades').get({Nombre: nombre}))
}

export const ObtenerCiudadPorId = async (id) => {
  return Promise.resolve(dbComun.table('Ciudades').get({Id: id}))
}

export const FiltrarCiudadPorNombre = async (nombre) => {
  return Promise.resolve(
    dbComun
      .table('Ciudades')
      .filter(
        ({Nombre}) =>
          Nombre.toLocaleUpperCase().indexOf(nombre.toLocaleUpperCase()) > -1
      )
      .toArray()
  )
}
