import React, {useState, useContext, useEffect} from 'react'
import {Input} from '../inputs'
import moment from 'moment'
import {Controller, useForm} from 'react-hook-form'
import {regexCorreoElectronico} from '../../constantes'
import {ErroresInput} from '../inputs'
import {ContextoAplicacion} from '../../contexto'
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import BotonSimple from '../Botones/BotonSimple'
import clsx from 'clsx'
import {FORMATO_FECHAS} from '../../constantes/formatoFechas'
import {SolicitarDescargaMasivaCertificados} from '../../microservicios'
import {useObtenerInformacion} from '../../hooks/useInformacion'

const DescargaMasivaDeConceptos = () => {
  const [fechasDescargaMasiva, setFechasDescargaMasivas] = useState({
    fechaInicial: '',
    fechaFinal: '',
    fechaMinima: moment()
      .subtract(31, 'days')
      .format(FORMATO_FECHAS.YYYY_MM_DD_GUIONES),
    fechaMaxima: moment()
      .subtract(1, 'days')
      .format(FORMATO_FECHAS.YYYY_MM_DD_GUIONES),
  })

  const contextoAplicacion = useContext(ContextoAplicacion)

  const [correoEnvioCertificado, setCorreoEnvioCertificado] = useState('')

  const {city} = useObtenerInformacion()

  const idEmpresaLogueada = city

  const {
    control,
    formState: {errors},
    trigger,
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
  }, [])

  const history = useHistory()

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
    history.go(0)
  }

  const [rangoDeFechasValido, setRangoDeFechasValido] = useState(false)

  const enviarCertificados = async () => {
    const payloadDescarga = {
      empresaId: parseInt(idEmpresaLogueada),
      fechaInicial: fechasDescargaMasiva?.fechaInicial,
      fechaFinal: fechasDescargaMasiva?.fechaFinal,
      correoElectronico: correoEnvioCertificado ?? '',
    }

    try {
      const response = await SolicitarDescargaMasivaCertificados(
        payloadDescarga
      )
      if (response.status === 200) {
        contextoAplicacion.setModal({
          abierto: true,
          titulo: 'El documento se enviará al correo electrónico',
          contenido: (
            <div className="text-center w-full mb-20">
              <p className="text-cendiatra text-xl font-semibold mt-14">
                La generación de este documento puede tomar un tiempo, por favor
                revise su bandeja de entrada.
              </p>
            </div>
          ),
          botones: [
            {
              nombre: 'Regresar',
              click: manejarCerrarModal,
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  const actualizarCorreo = (value) => {
    setCorreoEnvioCertificado(value)
  }

  const actualizarFechas = (nombre, valor) => {
    setFechasDescargaMasivas((prev) => {
      const nuevoEstado = {...prev, [nombre]: valor}

      if (nombre === 'fechaInicial' && moment(valor).isAfter(prev.fechaFinal)) {
        nuevoEstado.fechaFinal = ''
      }
      if (
        nombre === 'fechaFinal' &&
        moment(valor).isBefore(prev.fechaInicial)
      ) {
        nuevoEstado.fechaInicial = ''
      }
      return nuevoEstado
    })
  }

  useEffect(() => {
    const fechaInicial = moment(fechasDescargaMasiva.fechaInicial, 'YYYY-MM-DD')
    const fechaFinal = moment(fechasDescargaMasiva.fechaFinal, 'YYYY-MM-DD')

    const diferenciaEnDias = fechaFinal.diff(fechaInicial, 'days')

    setRangoDeFechasValido(Boolean(diferenciaEnDias > 31))
  }, [fechasDescargaMasiva])

  const isDisabled =
    !fechasDescargaMasiva.fechaInicial ||
    !fechasDescargaMasiva.fechaFinal ||
    Object.keys(errors).includes('correo') ||
    rangoDeFechasValido

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-7/12 flex items-center justify-center flex-wrap border border-cendiatra p-10 rounded-2xl">
        <div className="w-full flex justify-center items-center flex-wrap text-center">
          <span className="w-full items-center justify-center text-cendiatra text-xl font-bold">
            SELECCIONA EL RANGO DE FECHAS A DESCARGAR
          </span>
          <span className="w-full items-center justify-center text-cendiatra text-lg mt-5">
            Al confirmar se generará un archivo Excel con los enlaces a cada
            documento.
          </span>
          <span
            className={
              'w-full items-center justify-center text-xl text-cendiatra-rojo-1 mt-6'
            }
          >
            El rango de fechas máximo para seleccionar es de un mes a partir de
            ayer hacia atrás.
          </span>
        </div>
        <div className="w-full flex items-center justify-evenly text-start mt-10 mb-5">
          <Input
            estilosContenedor={'w-5/12'}
            onChange={(e) => actualizarFechas('fechaInicial', e.target.value)}
            estilosInput={clsx(
              'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
              {
                'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                  !fechasDescargaMasiva.fechaInicial || rangoDeFechasValido,
                'border border-cendiatra focus:border-cendiatra':
                  fechasDescargaMasiva.fechaInicial && !rangoDeFechasValido,
              }
            )}
            tipo={'date'}
            titulo={'Fecha inicial*'}
            valor={fechasDescargaMasiva.fechaInicial}
            fechaMaxima={fechasDescargaMasiva.fechaMaxima}
          />

          <Input
            estilosContenedor={'w-5/12'}
            onChange={(e) => actualizarFechas('fechaFinal', e.target.value)}
            estilosInput={clsx(
              'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
              {
                'border border-cendiatra-rojo-1 focus:border-cendiatra-rojo-1':
                  !fechasDescargaMasiva.fechaFinal || rangoDeFechasValido,
                'border border-cendiatra focus:border-cendiatra':
                  fechasDescargaMasiva.fechaFinal && !rangoDeFechasValido,
              }
            )}
            tipo={'date'}
            titulo={'Fecha final*'}
            valor={fechasDescargaMasiva.fechaFinal}
            fechaMaxima={fechasDescargaMasiva.fechaMaxima}
          />
        </div>
        <div className="w-11/12 flex justify-center items-center flex-wrap mb-10">
          <Controller
            name="correo"
            control={control}
            rules={{
              required: true,
              pattern: regexCorreoElectronico,
            }}
            render={({field: {onChange, value}}) => (
              <>
                <Input
                  onChange={(e) => {
                    onChange(e)
                    actualizarCorreo(e.target.value)
                  }}
                  estilosContenedor={'w-full mx-2 text-start'}
                  estilosInput={clsx(
                    'appearance-none rounded relative block w-full p-1.5 text-cendiatra-gris-3 focus:outline-none focus:ring-indigo-500 focus:z-10 sm:text-sm',
                    {
                      'border border-cendiatra-rojo-1 ':
                        Object.keys(errors).includes('correo'),
                      'border border-cendiatra':
                        !Object.keys(errors).includes('correo'),
                    }
                  )}
                  tipo={'text'}
                  titulo={'Correo*'}
                  valor={value}
                  placeholder={`correo@dominio.com`}
                />
                <ErroresInput
                  erroresFormulario={errors}
                  nombre={'correo'}
                  tipoError={'pattern'}
                  mensaje={'Ej: ejemplo@ejemplo.com'}
                />
              </>
            )}
          />
        </div>
        <div className="w-full flex justify-evenly items-center ">
          <BotonSimple
            estilosBoton={clsx(
              'w-40 h-14 rounded-lg bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px',
              {
                'bg-cendiatra-gris-2': isDisabled,
                'bg-fondoBoton': !isDisabled,
              }
            )}
            tipoDeBoton={'button'}
            funcion={enviarCertificados}
            deshabilitado={isDisabled}
            texto={'Enviar'}
          />
        </div>
      </div>
    </div>
  )
}

export default DescargaMasivaDeConceptos
