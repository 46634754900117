import {numericFormatter} from 'react-number-format'

/**
 * Permite dar formato a un valor para que sea como moneda.
 * @param {string} valorTexto Cadena que representa el valor a dar formato.
 * @returns Valor con el formato moneda.
 */
export const aplicarFormatoMoneda = (valorTexto, escalaDecimal = 2) => {
  const valorConFormato = numericFormatter(String(valorTexto), {
    decimalSeparator: ',',
    decimalScale: escalaDecimal,
    fixedDecimalScale: true,
    thousandSeparator: '.',
  })

  return `$${valorConFormato}`
}
