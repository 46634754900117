import Dexie from 'dexie'

export const dbCliente = new Dexie('CendiatraClienteCacheComercial')

dbCliente.version(1).stores({
  Ordenes:
    'Id, NumeroOrden, FechaCreacion, FechaAtencion, FechaVencimiento, UsuarioId, TipoExamenId, ObservacionesMedicas, EstadoId, SedeId, ConceptoId',
  Usuarios:
    'Id, TipoDocumentoId, NumeroDocumento, Nombres, Apellidos, Celular, Correo, TipoUsuarioId, Cargo',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbCliente.version(2).stores({
  Ordenes:
    'Id, NumeroOrden, FechaCreacion, FechaAtencion, FechaVencimiento, UsuarioId, TipoExamenId, ObservacionesMedicas, EstadoId, SedeId, ConceptoId',
  Usuarios:
    'Id, TipoDocumentoId, NumeroDocumento, Nombres, Apellidos, Celular, Correo, TipoUsuarioId, Cargo',
  Servicios: 'Id, CodigoInterno, Nombre, CupsId',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbCliente.version(3).stores({
  Servicios: 'Id, CodigoInterno, Nombre, CupsId',
  Paquetes: 'Id, CodigoInterno, Nombre',
  FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
})

dbCliente
  .version(4)
  .stores({
    Servicios: 'Id, CodigoInterno, Nombre, CupsId',
    Paquetes: 'Id, CodigoInterno, Nombre, Servicios',
    FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
  })
  .upgrade(async (trans) => {
    await dbCliente.Paquetes.clear()
    await dbCliente.FechasActualizacion.put({
      Tabla: 'Paquetes',
      FechaUltimaActualizacion: undefined,
    })
  })

dbCliente
  .version(5)
  .stores({
    Servicios: 'Id, CodigoInterno, Nombre, CupsId',
    Paquetes: 'Id, CodigoInterno, Nombre, Servicios',
    FechasActualizacion: 'Tabla, FechaUltimaActualizacion',
    Empresas:
      'Id, CodigoInterno, Nit, VerificacionNit, Nombre, EstaActiva, EjecutivoComercial, EmpresaPadreId, Observaciones, TipoFacturacion, Direccion, Telefono',
  })
  .upgrade(async (trans) => {
    await dbCliente.Paquetes.clear()
    await dbCliente.FechasActualizacion.put({
      Tabla: 'Paquetes',
      FechaUltimaActualizacion: undefined,
    })
  })

// ..
// Agregar versionamiento en esta sección si se desea hacer cambios en el esquema
// ..

dbCliente.open().catch(function (err) {
  console.error('Fallo al abrir el cache cliente: ' + (err.stack || err))
})
