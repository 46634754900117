import React from 'react'
import {BotonConImagen} from '../Botones'

const HeaderSeleccionarTabla = ({
  data,
  funcionBoton,
  imagenBoton,
  seleccion,
  listaOrdenes,
  funcionSeleccion,
  deshabilitado = false,
}) => {
  return (
    <div className="flex w-full justify-evenly items-center">
      <div className="w-full flex">
        <div
          className={`w-6/12 flex justify-center items-center flex-wrap `}
        >
          <BotonConImagen
            imagen={imagenBoton}
            estilosImagen={`w-10 h-10 `}
            funcionAEjecutar={funcionBoton}
          />
        </div>
        <div className="w-6/12 flex justify-center items-center flex-wrap -ml-2">
          <span className="w-full text-center my-2">{'Seleccionar todo'}</span>
          <div
            className="flex w-full justify-center items-center"
            id="descargar"
          >
            <input
              type="checkbox"
              onChange={(evt) => {
                funcionSeleccion(evt.target.checked ? data : [])
              }}
              className="checkboxEspecial personalizado -ml-2"
              checked={seleccion.length === listaOrdenes.length}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSeleccionarTabla
