import React from 'react'
import {aplicarFormatoMoneda} from '../../utils'

const NumeroConTitulo = ({
  titulo,
  estilosContenedor = 'w-full',
  valor = '',
  formatoMoneda = true,
  estilosAdicionales = '',
  numeroDecimales = 0,
  funcion = null,
  placeholder = 0,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-center items-center flex-wrap `}
    >
      <span className="text-cendiatra w-full flex justify-center items-center">
        {titulo}
      </span>
      <input
        className={`w-full text-cendiatra-gris-1 flex justify-center items-center text-center ${estilosAdicionales}`}
        value={
          formatoMoneda ? aplicarFormatoMoneda(valor, numeroDecimales) : valor
        }
        placeholder={placeholder}
        onChange={funcion}
      />
    </div>
  )
}

export default NumeroConTitulo
