import React, {useContext, useEffect, useState} from 'react'
import {ESTADOS_TRANSACCION} from '../../constantes'
import {useHistory} from 'react-router-dom'
import {aplicarFormatoMoneda} from '../../utils'
import Lottie from 'lottie-react'
import check from '../../imagenes/lotties/check.json'
import {
  FachadaGenerarReciboCajaRecarga,
  ObtenerReciboDeCaja,
} from '../../microservicios'
import {ContextoRecargarSaldo} from '../../contexto'

const EstadoTransaccion = ({estado, mensajes}) => (
  <div className="w-full flex-col justify-center items-center text-center text-cendiatra text-3xl my-10 font-bold flex-wrap">
    <p className="w-full mb-10">{estado.toLocaleUpperCase()}</p>
    {mensajes.map((mensaje, index) => (
      <p key={index} className="w-full mb-10">
        {mensaje}
      </p>
    ))}
  </div>
)

const EstadosRecargaSaldo = ({transaccionEstadoSaldo}) => {
  const {estadoTransaccion, valor, transaccionId} = transaccionEstadoSaldo

  const contextoRecargarSaldo = useContext(ContextoRecargarSaldo)

  const [pdfUrl, setPdfUrl] = useState('')

  const history = useHistory()

  const irRecargarsaldos = () => {
    history.push('/recargarsaldo')
  }

  const irVerDetalleYMovimiento = () => {
    history.push('/verdetalleymovimiento')
  }

  const irAOrdenes = () => {
    history.push('/crearorden')
  }

  const finalizar = () => {
    history.push('/')
  }

  const imprimirReciboDeCaja = () => {
    window.open(pdfUrl)
  }

  useEffect(() => {
    if (estadoTransaccion === ESTADOS_TRANSACCION.APROBADA) {
      const movimientoId = sessionStorage.getItem('movimientoId')

      const empresaData = JSON.parse(sessionStorage.getItem('empresaData'))

      const payload = {
        movimientoId,
        sede: empresaData,
        empresa: empresaData,
        urlQr:
          'https://uatcendiatra.blob.core.windows.net/staticweb/codigo_qr.png',
      }

      FachadaGenerarReciboCajaRecarga(payload).then((response) => {
        if (response.data) {
          setPdfUrl(response.data)
        }
      })
    }
  }, [estadoTransaccion])

  const estadosMensajes = {
    [ESTADOS_TRANSACCION.RECHAZADA]: [
      'Tu cuenta NO ha sido recargada',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.FALLIDA]: [
      'Tu cuenta NO ha sido recargada',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.APROBADA]: [
      <Lottie className="w-52 mx-auto" animationData={check} loop={true} />,
      'Tu cuenta ha sido recargada exitosamente con:',
      `${aplicarFormatoMoneda(valor ?? '')}`,
      'Ahora puedes crear órdenes que serán descontadas de tu saldo disponibles',
    ],
    [ESTADOS_TRANSACCION.PENDIENTE]: [
      'Tu cuenta está pendiente por recargar',
      'Generalmente, en unas pocas horas verás reflejado el monto de tu cuenta, nos encontramos en el proceso de validación con tu entidad bancaria para garantizar una transacción exitosa.',
      'Si requieres más información acerca de la transacción, por favor contacta a tu entidad bancaria.',
    ],
    [ESTADOS_TRANSACCION.EXPIRADA]: [
      'Tu cuenta NO ha sido recargada',
      '¿Qué deseas hacer?',
    ],
    [ESTADOS_TRANSACCION.NO_AUTORIZADA]: [
      'Tu cuenta NO ha sido recargada',
      'comunícate con la entidad bancaria',
      '¿Qué deseas hacer?',
    ],
  }

  return (
    <div>
      {estadoTransaccion in estadosMensajes && (
        <EstadoTransaccion
          estado={estadoTransaccion}
          mensajes={estadosMensajes[estadoTransaccion]}
        />
      )}

      {estadoTransaccion !== ESTADOS_TRANSACCION.APROBADA ? (
        <div className="w-full flex justify-evenly items-center m-5">
          {estadoTransaccion !== ESTADOS_TRANSACCION.PENDIENTE ? (
            <button
              key={'Regresar a recargar cuenta'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irRecargarsaldos}
            >
              {'Regresar a recargar cuenta'}
            </button>
          ) : (
            ''
          )}

          {estadoTransaccion === ESTADOS_TRANSACCION.PENDIENTE ? (
            <button
              key={'Ver movimientos y detalles '}
              className="w-72 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irVerDetalleYMovimiento}
            >
              {'Ver movimientos y detalles '}
            </button>
          ) : (
            <button
              key={'Finalizar'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={finalizar}
            >
              {'Finalizar'}
            </button>
          )}
        </div>
      ) : (
        <>
          <div className="w-full flex justify-evenly items-center m-5">
            <button
              key={'Finalizar'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={finalizar}
            >
              {'Volver a Inicio'}
            </button>

            <button
              key={'Crear órdenes'}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={irAOrdenes}
            >
              {'Crear órdenes'}
            </button>

            <button
              key={'Imprimir recibo de caja'}
              className={`${
                pdfUrl === '' ? 'bg-gray-300' : 'bg-fondoBoton'
              } w-52 h-14 rounded-lg  bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px`}
              type="button"
              disabled={pdfUrl === ''}
              onClick={imprimirReciboDeCaja}
            >
              {'Imprimir recibo de caja'}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default EstadosRecargaSaldo
