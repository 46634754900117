import React from 'react'
import {Route, Switch, Redirect} from 'react-router-dom'
import Home from './paginas/Home'
import Ayuda from './paginas/Ayuda'
import CambioContraseña from './paginas/CambioContraseña'
import {OrdenConContexto} from './contexto/ContextoOrden'
import {RecargarSaldoConContexto} from './contexto/ContextoRecargarSaldo'
import ResultadosPaciente from './componentes/Ordenes/ResultadosPaciente'
import CrearOrden from './paginas/Ordenes/CrearOrden'
import EditarOrden from './paginas/Ordenes/EditarOrden'
import {RUTAS_APLICACION} from './constantes/rutas'
import RecargarSaldo from './paginas/PerfilContado/RecargarSaldo'
import DetalleYMovimientos from './paginas/PerfilContado/DetalleYMovimientos'
import FinalizarRecarga from './paginas/PerfilContado/FinalizarRecarga'
import GestionAtenciones from './paginas/PerfilContado/RecursosHumanos/GestionAtenciones'
import GestionDeOrdenes from './paginas/PerfilContado/RecursosHumanos/GestionDeOrdenes'
import OtrosServicios from './paginas/PerfilContado/OtrosServicios'
import CargaMasiva from './paginas/Ordenes/CargaMasiva'
import DescargaMasiva from './paginas/PerfilContado/DescargaMasiva'
import FinalizarRecargaOtrosServicios from './paginas/PerfilContado/FinalizarRecargaOtrosServicios'

const Router = () => {
  return (
    <Switch>

      <Route path={RUTAS_APLICACION.ORDENES}>
        <GestionDeOrdenes />
      </Route>

      <Route path={RUTAS_APLICACION.CARGA_MASIVA_ORDEN}>
        <CargaMasiva/>
      </Route>

      <Route path={RUTAS_APLICACION.GESTION_ATENCIONES}>
        <GestionAtenciones />
      </Route>
      <Route path={RUTAS_APLICACION.CAMBIAR_CONTRASEÑA}>
        <CambioContraseña />
      </Route>
      <Route path={RUTAS_APLICACION.AYUDA}>
        <Ayuda />
      </Route>
      <Route path={RUTAS_APLICACION.RESULTADOS_PACIENTE}>
        <ResultadosPaciente />
      </Route>
      <Route path={RUTAS_APLICACION.CREAR_ORDEN}>
        <OrdenConContexto>
          <CrearOrden />
        </OrdenConContexto>
      </Route>
      <Route path={RUTAS_APLICACION.EDITAR_ORDEN}>
        <OrdenConContexto>
          <EditarOrden />
        </OrdenConContexto>
      </Route>
      <Route path={RUTAS_APLICACION.RECARGAR_SALDO}>
        <RecargarSaldoConContexto>
          <RecargarSaldo />
        </RecargarSaldoConContexto>
      </Route>
      <Route path={RUTAS_APLICACION.PAGAR_OTROS_SERVICIOS}>
        <RecargarSaldoConContexto>
          <OtrosServicios />
        </RecargarSaldoConContexto>
      </Route>
      <Route path={RUTAS_APLICACION.FINALIZAR_RECARGA_OTROS_SERVICIOS}>
        <RecargarSaldoConContexto>
          <FinalizarRecargaOtrosServicios />
        </RecargarSaldoConContexto>
      </Route>
      <Route path={RUTAS_APLICACION.FINALIZAR_RECARGA}>
        <RecargarSaldoConContexto>
          <FinalizarRecarga />
        </RecargarSaldoConContexto>
      </Route>
      <Route path={RUTAS_APLICACION.VER_DETALLE_Y_MOVIMIENTO}>
        <DetalleYMovimientos />
      </Route>
      <Route path={RUTAS_APLICACION.DESCARGA_MASIVA}>
        <DescargaMasiva />
      </Route>
      <Route path={RUTAS_APLICACION.HOME}>
        <Home />
      </Route>
    </Switch>
  )
}
export default Router
