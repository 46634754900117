import {getRestApi} from './ApiAxios'

export const ObtenerDatosOtrosServicios = async () => {
  const apiReq = await getRestApi()
  return await apiReq.get('gato-api-function/ObtenerDatosOtrosServicios')
}

export const ValidarEstadoTransaccionOtroServicio = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.patch(
    'gato-api-function/ValidarEstadoTransaccionOtroServicio',
    payload
  )
}

export const ServicioPagarOtrosServicios = async (payload) => {
  const apiReq = await getRestApi()
  return await apiReq.put('gato-api-function/PagarOtrosServicios', payload)
}
