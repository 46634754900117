import {dbComun} from '../bases-datos'

export const ObtenerDepartamentoPorIdCiudad = async (IdCiudad) => {
  let ciudad = await dbComun.table('Ciudades').get({Id: IdCiudad})
  return Promise.resolve(
    dbComun.table('Departamentos').get({Id: ciudad.DepartamentoId})
  )
}

export const ObtenerDepartamentoPorId = async (id) => {
  return Promise.resolve(dbComun.table('Departamentos').get({Id: id}))
}
