import {TEXTO_VISUAL} from '../../constantes'

const CeldaSeleccionar = ({
  row,
  seleccionados = [],
  esSeleccionadoUnCheckbox,
  mostrarAtenciones,
}) => {
  const validarCheckboxActivo = (estado) => {
    if (mostrarAtenciones && estado !== TEXTO_VISUAL.ATENCIONES.ATENDIDA) {
      return 'checkboxEspecialDeshabilitado'
    }
    return 'checkboxEspecial personalizado'
  }

  const validarSiEsOrdenOAtencion = (fila) => {
    let TIPO_GESTION = mostrarAtenciones ? 'atencionId' : 'id'
    return Boolean(
      seleccionados.find(
        (seleccion) => seleccion[TIPO_GESTION] === fila[TIPO_GESTION]
      )
    )
  }

  const habilitarODeshabilitarFila = (estadoFila) => {
    let TIPO_GESTION = mostrarAtenciones
      ? TEXTO_VISUAL.ATENCIONES.ATENDIDA
      : TEXTO_VISUAL.ORDENES.ESTADO.ATENDIDA

    return Boolean(estadoFila !== TIPO_GESTION)
  }

  return (
    <div className="flex justify-center items-center">
      <div className="w-full">
        <input
          type="checkbox"
          className={
            mostrarAtenciones
              ? validarCheckboxActivo(row.original.estadoAtencion)
              : validarCheckboxActivo(row.original.estado)
          }
          checked={validarSiEsOrdenOAtencion(row.original)}
          onChange={(evt) => {
            if (row.original.estado !== TEXTO_VISUAL.ORDENES.ESTADO.ATENDIDA) {
              evt.preventDefault()
            }
            esSeleccionadoUnCheckbox(row.original)
          }}
          disabled={
            mostrarAtenciones
              ? habilitarODeshabilitarFila(row.original.estadoAtencion)
              : false
          }
        />
      </div>
    </div>
  )
}

export default CeldaSeleccionar
