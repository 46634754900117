import React from 'react'

const BotonConImagen = ({
  estilosContenedor,
  imagen,
  textoAlternativo,
  funcionAEjecutar,
  estilosImagen,
  rotacion,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-center flex-wrap items-center cursor-pointer`}
    >
      <img
        className={`${estilosImagen}`}
        src={imagen}
        alt={textoAlternativo}
        onClick={funcionAEjecutar}
        style={{transform: `rotate(${rotacion}deg)`}}
      />
    </div>
  )
}

export default BotonConImagen
