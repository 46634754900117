import {getRestApi} from './ApiAxios'

export const ObtenerReferencias = async (listaReferencias) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'referencia-function/ObtenerValoresReferencia?tipo=' + listaReferencias
  )
}

export const ObtenerSedes = async () => {
  const restApi = await getRestApi()
  return await restApi.get('referencia-function/ObtenerSedes')
}

export const ObtenerCiudades = async () => {
  const restApi = await getRestApi()
  return await restApi.get('referencia-function/ObtenerCiudades')
}

export const ObtenerDepartamentos = async () => {
  const restApi = await getRestApi()
  return await restApi.get('referencia-function/ObtenerDepartamentos')
}

export const obtenerAliados = async () => {
  const restApi = await getRestApi()
  return await restApi.get('referencia-function/ObtenerAliados')
}
