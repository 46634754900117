import {Bar, Cell, Line, Tooltip, XAxis, YAxis} from 'recharts'
import {COLORES_GRAFICOS, TEXTO_VISUAL} from '../../constantes'
import {
  ContenedorCendiatra,
  GraficaBarras,
  GraficaLineal,
  TituloGrafica,
} from '../Graficas'
import {useMsal} from '@azure/msal-react'
import {useEffect, useState} from 'react'
import {
  ObtenerExamenesMasFrecuentesPorEmpresaId,
  ObtenerFacturadoAtencionesPorMesYEmpresaId,
  ObtenerOrdenesPorSemanaActualYEmpresaId,
} from '../../microservicios'
import {ObtenerServicioPorId} from '../../cache/servicios-cache'
import {aplicarFormatoMoneda, simplificarTexto} from '../../utils'
import moment from 'moment'

const TablaGraficas = () => {
  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [datosGraficaExamenesFrecuentes, setDatosGraficaExamenesFrecuentes] =
    useState([])
  const [datosGraficaFacturacion, setDatosGraficaFacturacion] = useState({})

  const [datosGraficaOrdenes, setDatosGraficaOrdenes] = useState([])

  function capitalizarPrimeraLetra(dia) {
    if (typeof dia !== 'string' || dia.length === 0) {
      return ''
    }

    const primeraLetraCapitalizada = dia.charAt(0)?.toUpperCase()

    return primeraLetraCapitalizada
  }

  useEffect(() => {
    const consultarDatosGrafica = async () => {
      try {
        const res = await ObtenerExamenesMasFrecuentesPorEmpresaId(idEmpresa)
        const respuesta = res.data
        const respuestaTransformada = await Promise.all(
          respuesta.map(async (resp) => ({
            cantidad: resp.cantidad,
            name: (await ObtenerServicioPorId(resp.servicioId))?.Nombre,
          }))
        )
        setDatosGraficaExamenesFrecuentes(respuestaTransformada)
      } catch (error) {
        console.log(error)
      }
    }

    const consultarDatosFacturas = async () => {
      try {
        const fecha = moment()
        const res = await ObtenerFacturadoAtencionesPorMesYEmpresaId(
          idEmpresa,
          fecha
        )
        const respuesta = res.data
        const fechaActual = fecha
          .format('MMMM YYYY')
          .replace(/^\w/, (c) => c?.toUpperCase())
        const datosTransformados = respuesta.facturacionSemanas.map((resp) => ({
          name: 'Semana' + ' ' + resp.semana,
          total: resp.total,
        }))

        const respuestaTransformada = {
          mes: fechaActual,
          datos: datosTransformados,
          total: respuesta.total,
        }

        setDatosGraficaFacturacion(respuestaTransformada)
      } catch (error) {
        console.log(error)
      }
    }

    const consultarDatosOrdenes = async () => {
      try {
        const res = await ObtenerOrdenesPorSemanaActualYEmpresaId(idEmpresa)
        if (res.status === 204) {
          return
        }

        const respuesta = res.data.resumenPorDia
        const datosTransformados = respuesta.map((resp) => ({
          name: capitalizarPrimeraLetra(resp.nombreDia),
          cantidadCreadas: resp.cantidadCreadas,
          cantidadAtendidas: resp.cantidadAtendidas,
        }))

        setDatosGraficaOrdenes(datosTransformados)
      } catch (error) {
        console.log(error)
      }
    }
    consultarDatosGrafica()
    consultarDatosFacturas()
    consultarDatosOrdenes()
  }, [])

  // Lineas de la gráfica de ordenes.
  const lineasGraficasOrdenes = (
    <>
      <Line
        dot={false}
        yAxisId="left"
        name={TEXTO_VISUAL.HOME.GRAFICOS.ORDENES.LEYENDAS.ORDENES_CREADAS}
        strokeWidth={4}
        dataKey="cantidadAtendidas"
        stroke="#28ACB9"
        activeDot={{r: 8}}
        legendType="square"
        iconSize={20}
      />
      <Line
        yAxisId="left"
        name={TEXTO_VISUAL.HOME.GRAFICOS.ORDENES.LEYENDAS.ORDENES_ATENDIDAS}
        strokeWidth={0}
        dot={{strokeWidth: 3, fill: '#0871B4', stroke: '#0871B4'}}
        dataKey="cantidadCreadas"
        activeDot={{r: 8}}
        legendType="square"
        iconSize={20}
      />
    </>
  )

  // Barras de la gráfica de facturación.
  const barrasGraficaFacturacion = (
    <Bar
      dataKey="total"
      name={TEXTO_VISUAL.HOME.GRAFICOS.FACTURACION.TOOLTIP.DINERO_FACTURADO}
      barSize={20}
      fill="#28ACB9"
      radius={[20, 20, 20, 20]}
    />
  )

  // Barras de la gráfica de examenes frecuentes.
  const barrasGraficaExamenesFrecuentes = (
    <Bar
      dataKey="cantidad"
      name={TEXTO_VISUAL.HOME.GRAFICOS.EXAMENES_MAS_FRECUENTES.TOOLTIP.CANTIDAD}
      barSize={20}
      radius={[20, 20, 20, 20]}
    >
      {datosGraficaExamenesFrecuentes.length &&
        datosGraficaExamenesFrecuentes.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={COLORES_GRAFICOS[index % (COLORES_GRAFICOS.length - 1)]}
          />
        ))}
    </Bar>
  )

  return (
    <>
      <div className="w-full bg-grisAlternativo flex flex-wrap justify-evenly items-center">
        <div className="w-full bg-white flex justify-evenly items-center flex-wrap">
          <ContenedorCendiatra clasesAdicionales="w-1/4">
            <TituloGrafica
              tituloExtra={TEXTO_VISUAL.HOME.GRAFICOS.ORDENES.POR_SEMANA_ACTUAL}
            />
            <GraficaLineal
              datos={datosGraficaOrdenes}
              lineas={lineasGraficasOrdenes}
              ejesX={<XAxis dataKey="name" padding={{left: 30}} />}
              ejesY={
                <>
                  <YAxis yAxisId="left" />
                  <YAxis yAxisId="right" orientation="right" />
                </>
              }
            />
          </ContenedorCendiatra>
          <ContenedorCendiatra>
            <TituloGrafica
              tituloExtra={
                TEXTO_VISUAL.HOME.GRAFICOS.EXAMENES_MAS_FRECUENTES.TITULO
              }
            />
            <GraficaBarras
              datos={datosGraficaExamenesFrecuentes}
              ejesX={
                <XAxis
                  dataKey="name"
                  minTickGap={0}
                  tickFormatter={(value) =>
                    simplificarTexto(String(value ?? '').toUpperCase())
                  }
                />
              }
              ejesY={<YAxis />}
              barras={barrasGraficaExamenesFrecuentes}
              reducirNombre={true}
            />
          </ContenedorCendiatra>
          <ContenedorCendiatra>
            <TituloGrafica
              tituloExtra={TEXTO_VISUAL.HOME.GRAFICOS.FACTURACION.ULTIMO_MES}
            />
            <div className="w-full flex justify-evenly items-center h-10 mb-5 text-14px">
              <div className="w-1/3 flex justify-center items-center"></div>
              <div className="w-1/3 flex justify-center items-center">
                <span className="font-helveticaNegrita text-cendiatra-gris-1">
                  {datosGraficaFacturacion.mes}
                </span>
              </div>
              <div className="w-1/3 flex flex-col justify-center text-right">
                <span className="text-cendiatra-gris-1 ">
                  {`${TEXTO_VISUAL.HOME.GRAFICOS.FACTURACION.TOTAL_FACTURADO}: `}
                </span>
                <span className="text-newCendiatra font-helveticaNegrita text-17px">
                  {aplicarFormatoMoneda(String(datosGraficaFacturacion.total))}
                </span>
              </div>
            </div>
            <GraficaBarras
              altura={250}
              datos={datosGraficaFacturacion.datos}
              ejesX="name"
              ejesY={
                <YAxis
                  type="number"
                  tickFormatter={(value) => aplicarFormatoMoneda(String(value))}
                />
              }
              tooltip={
                <Tooltip
                  formatter={(value) => aplicarFormatoMoneda(String(value))}
                />
              }
              barras={barrasGraficaFacturacion}
              margins={{
                top: 5,
                right: 30,
                left: 50,
                bottom: 5,
              }}
            />
          </ContenedorCendiatra>
        </div>
      </div>
    </>
  )
}

export default TablaGraficas
