export const CIUDAD_ID = {
  MEDELLIN: 2,
  CALI: 1005,
  BOGOTA: 150,
}

export const SEDE_ID = {
  MEDELLIN_AGUACATALA: 1,
  BOGOTA_PRINCIPAL: 19,
  CALI_LA_FLORA: 28,
}
