const Tooltip = ({children, tooltip, width = 250, activo = false}) => {
  return (
    <div className="group relative inline-block w-full ">
      {children}
      <span
        className={`inline-block absolute z-20 py-3 px-3 rounded-lg  tooltip ${
          activo ? 'visible opacity-100' : 'invisible opacity-0'
        } ml-1  bg-white shadow-lg shadow-slate-600`}
        style={{
          width: width,
        }}
      >
        {tooltip}
      </span>
    </div>
  )
}

export default Tooltip
