import React, {useState, useEffect, useContext} from 'react'
import {ContenedorTituloResaltado} from '../Contenedores'
import {Controller, useForm} from 'react-hook-form'
import {BotonConImagen, BotonSimple, BotonTextoImagenAlFinal} from '../Botones'
import BotonAgregar from '../../imagenes/addButton.png'
import {NumeroConTitulo} from '../MostrarEnPantalla'
import {aplicarFormatoMoneda} from '../../utils'
import {ListaDesplegable, Input} from '../inputs'
import {
  IconoAgregarAlCarrito,
  IconoServicioEnCarrito,
  BotonQuitar,
} from '../../imagenes'
import {LeyendaConInputRadio} from '../Leyendas'
import {ObtenerTodasLasSedes} from '../../cache/servicios-cache'
import TablaDinamica from '../TablaDinamica'
import CeldaConBoton from '../Ordenes/CeldaConBoton'
import {ObtenerDatosOtrosServicios} from '../../microservicios/OtrosServicios'
import {TIPOS_OTROS_SERVICIOS} from '../../constantes'
import {CIUDAD_ID, SEDE_ID} from '../../constantes'

const PagarOtrosServicios = ({funcionCambiarDePantalla}) => {
  const servicioSeleccionadoPorDefecto = {
    nombreServicio: '',
    valorUnidad: 0,
    valorTotal: 0,
    cantidad: 0,
    iva: 0,
    ivaCalculado: 0,
    tipoServicio: '',
    id: '',
    numeroDeServicios: 0,
  }

  const datosTiqueterasPorDefecto = {
    nombreDeQuienRecibe: '',
    ciudadId: '',
    barrio: '',
    direccion: '',
    telefono: '',
    envioDomicilio: true,
  }
  const sedePorDefecto = {
    id: '',
    nombreSede: '',
    direccion: '',
  }

  const {
    control,
    trigger,
    formState: {isValid},
  } = useForm({
    mode: 'all',
    defaultValues: {},
  })

  const [servicioSeleccionado, setServicioSeleccionado] = useState(
    servicioSeleccionadoPorDefecto
  )
  const [cantidadServicios, setCantidadServicios] = useState('')

  const [radialSeleccionado, setRadialSeleccionado] = useState(true)

  const [todasLasSedes, setTodasLasSedes] = useState([])

  const [sedeSeleccionada, setSedeSeleccionada] = useState(sedePorDefecto)

  const [datosTablaServicios, setDatosTablaServicios] = useState([])

  const [valoresTabla, setValoresTabla] = useState({
    totalServicios: 0,
    totalIva: 0,
    totalTransaccion: 0,
  })
  const [listaDeServicios, setListaDeServicios] = useState([])

  const [datosTiqueteras, setDatosTiqueteras] = useState(
    datosTiqueterasPorDefecto
  )

  useEffect(() => {
    const obtenerDatosServicios = async () => {
      try {
        const respuesta = await ObtenerDatosOtrosServicios()
        if (respuesta.status === 200) {
          setListaDeServicios(
            respuesta?.data?.map((servicio) => {
              return {
                Id: servicio?.otroServicioId,
                Nombre:
                  `${servicio?.nombre} x ${servicio.cantidad}`.toUpperCase(),
                TipoServicio: servicio?.tipoServicioId,
                Precio: servicio?.valor,
                Cantidad: servicio?.cantidad,
                Iva:
                  servicio?.tipoServicioId?.toString() ===
                  TIPOS_OTROS_SERVICIOS.TIQUETERA
                    ? 0
                    : servicio?.iva,
                Rangos: servicio?.rangos,
              }
            })
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    obtenerDatosServicios()
  }, [])

  const CiudadesPermitidas = [
    {
      Id: 2,
      Nombre: 'MEDELLIN',
      DepartamentoId: 2,
    },
    {
      Id: 1005,
      Nombre: 'CALI',
      DepartamentoId: 26,
    },
    {
      Id: 150,
      Nombre: 'BOGOTÁ',
      DepartamentoId: 4,
    },
  ]

  const filtrarSedes = (datos) => {
    return datos.filter((sede) => {
      if (
        sede.CiudadId === CIUDAD_ID.MEDELLIN &&
        sede.Id === SEDE_ID.MEDELLIN_AGUACATALA
      )
        return true
      if (
        sede.CiudadId === CIUDAD_ID.BOGOTA &&
        sede.Id === SEDE_ID.BOGOTA_PRINCIPAL
      )
        return true
      if (sede.CiudadId === CIUDAD_ID.CALI && sede.Id === SEDE_ID.CALI_LA_FLORA)
        return true
      return ![CIUDAD_ID.MEDELLIN, CIUDAD_ID.BOGOTA, CIUDAD_ID.CALI].includes(
        sede.CiudadId
      )
    })
  }

  useEffect(() => {
    const obtenerSedes = async () => {
      const sedes = await ObtenerTodasLasSedes()
      setTodasLasSedes(filtrarSedes(sedes))
    }

    obtenerSedes()
  }, [])

  useEffect(() => {
    setServicioSeleccionado((prev) => {
      return {
        ...prev,
        valorTotal:
          parseFloat(prev?.valorUnidad) * parseFloat(cantidadServicios || 1),
      }
    })
  }, [cantidadServicios])

  const manejarExamenSeleccionado = (servicioId) => {
    setServicioSeleccionado(servicioSeleccionadoPorDefecto)
    setCantidadServicios('')
    if (servicioId) {
      const servicioSeleccionado = listaDeServicios?.find(
        (elemento) => elemento.Id == servicioId
      )

      const valorInicialRangos = obtenerValorPorCantidad(
        1,
        servicioSeleccionado?.Rangos
      )
      return setServicioSeleccionado({
        nombreServicio: servicioSeleccionado?.Nombre,
        valorUnidad: servicioSeleccionado?.Precio || valorInicialRangos,
        valorTotal: servicioSeleccionado?.Precio || valorInicialRangos,
        iva: servicioSeleccionado?.Iva,
        ivaCalculado: servicioSeleccionado?.Iva
          ? calcularIva(
              parseFloat(servicioSeleccionado?.Precio || valorInicialRangos),
              parseFloat(servicioSeleccionado?.Iva)
            )
          : 0,
        numeroDeServicios: 1,
        cantidad: servicioSeleccionado.Cantidad,
        tipoServicio: servicioSeleccionado?.TipoServicio,
        id: servicioSeleccionado?.Id,
        rangos: servicioSeleccionado?.Rangos,
      })
    }
    return setServicioSeleccionado(servicioSeleccionadoPorDefecto)
  }

  useEffect(() => {
    trigger()
  }, [servicioSeleccionado])

  const obtenerValorPorCantidad = (cantidad, rangos) => {
    const valorPrimerRango = rangos[0]?.valor
    for (const rango of rangos) {
      if (!rango.rangoMaximo && cantidad >= rango.rangoMinimo) {
        return rango.valor
      } else if (
        cantidad >= rango.rangoMinimo &&
        cantidad <= rango.rangoMaximo
      ) {
        return rango.valor
      }
    }
    return valorPrimerRango
  }

  const manejarCantidadDeServicios = (cantidad) => {
    setCantidadServicios(cantidad.target.value)
    const valorRango = obtenerValorPorCantidad(
      cantidad.target.value,
      servicioSeleccionado?.rangos
    )

    agregarValoresPropiedad(cantidad.target.value, 'numeroDeServicios')

    setServicioSeleccionado((prev) => {
      return {
        ...prev,
        valorUnidad: valorRango || prev?.valorUnidad,
        valorTotal: valorRango || prev?.valorTotal,
        ivaCalculado: prev?.iva
          ? calcularIva(
              parseFloat(valorRango || prev?.valorUnidad) *
                parseFloat(cantidad.target.value || 1),
              parseFloat(prev?.iva)
            )
          : 0,
      }
    })
  }

  const cambiarInputRadial = () => {
    setRadialSeleccionado((prev) => {
      const nuevoRadialSeleccionado = !prev
      agregarValoresTiquetera(nuevoRadialSeleccionado, 'envioDomicilio')
      return nuevoRadialSeleccionado
    })
    trigger()
  }

  const manejarSedeSeleccionada = (sedeId) => {
    if (sedeId) {
      const sedeSeleccionada = todasLasSedes?.find(
        (elemento) => elemento.Id == sedeId
      )
      return setSedeSeleccionada({
        id: sedeSeleccionada.Id,
        nombresede: sedeSeleccionada.Nombre,
        direccion: sedeSeleccionada.Direccion,
      })
    }

    return setSedeSeleccionada(sedePorDefecto)
  }

  const agregarServicio = async () => {
    const servicioNoSeleccionado = !servicioSeleccionado?.nombreServicio
    const formularioLleno =
      !isValid &&
      radialSeleccionado &&
      servicioSeleccionado?.tipoServicio.toString() ===
        TIPOS_OTROS_SERVICIOS.TIQUETERA
    const sedeNoSeleccionada = !sedeSeleccionada.id && !radialSeleccionado

    if (
      servicioNoSeleccionado ||
      formularioLleno ||
      (sedeNoSeleccionada &&
        servicioSeleccionado.tipoServicio === TIPOS_OTROS_SERVICIOS.TIQUETERA)
    ) {
      return
    }
    const existeServicio = datosTablaServicios.find(
      (elemento) => elemento.id === servicioSeleccionado.id
    )
    if (existeServicio) {
      return
    }
    setDatosTablaServicios((prev) => [...prev, servicioSeleccionado])
    setServicioSeleccionado(servicioSeleccionadoPorDefecto)
    setCantidadServicios('')
  }

  const calcularIva = (valorTotal, valorIVA) => {
    return (valorTotal * valorIVA) / 100
  }

  const eliminarServicio = (id) => {
    const nuevoArreglo = datosTablaServicios.filter(
      (servicio) => servicio.id !== id
    )
    setDatosTablaServicios(nuevoArreglo)
  }
  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'nombreServicio',
        className: 'w-8/12 text-start',
      },
      {
        Header: '',
        accessor: 'numeroDeServicios',
        className: 'w-1/12 text-13px',
      },

      {
        Header: '',
        accessor: 'valorUnidad',
        className: 'w-1/12 text-13px',
        Cell: (row) => (
          <span>{aplicarFormatoMoneda(row.row.original.valorUnidad, 0)}</span>
        ),
      },
      {
        Header: '',
        accessor: 'valorTotal',
        className: 'w-1/12 text-13px',
        Cell: (row) => (
          <span>{aplicarFormatoMoneda(row.row.original.valorTotal, 0)}</span>
        ),
      },
      {
        Header: '',
        accessor: 'iva',
        className: 'w-1/12 text-13px',
        Cell: (row) => {
          if (row.row.original.iva) {
            return <span>{`${row.row.original.iva}%`}</span>
          }
          return <span className="normal-case">No aplica</span>
        },
      },
      {
        Header: '',
        id: 'boton',
        className: 'w-1/12 -mr-10  mb-2',
        Cell: (row) => (
          <CeldaConBoton
            funcion={() => eliminarServicio(row.row.original.id)}
            imagen={BotonQuitar}
            estilosImagen="w-6 h-6"
            deshabilitado={true}
          />
        ),
      },
    ],
    [datosTablaServicios]
  )

  const agregarValoresPropiedad = (valor, nombre) => {
    setServicioSeleccionado((prev) => {
      return {
        ...prev,
        [nombre]: valor,
      }
    })
  }

  const agregarValoresTiquetera = (valor, nombre) => {
    setDatosTiqueteras((prev) => {
      return {
        ...prev,
        [nombre]: valor,
      }
    })
  }

  useEffect(() => {
    const sumatoriaServicios = datosTablaServicios
      .map((item) => parseFloat(item.valorTotal))
      .reduce((prev, curr) => prev + curr, 0)
      .toFixed(2)

    const valorIva = datosTablaServicios
      .reduce((prev, curr) => prev + parseFloat(curr.ivaCalculado), 0)
      .toFixed(2)

    const total = parseFloat(sumatoriaServicios) + parseFloat(valorIva)

    setValoresTabla({
      totalServicios: sumatoriaServicios,
      totalIva: valorIva,
      totalTransaccion: total,
    })
  }, [datosTablaServicios.length, servicioSeleccionado?.numeroDeServicios])

  return (
    <>
      <ContenedorTituloResaltado
        titulo={'AGREGAR SERVICIO AL CARRITO'}
        activarBorde={false}
        imagen={IconoAgregarAlCarrito}
      >
        <div className="w-full flex items-center justify-center ">
          <ListaDesplegable
            estilosContenedor={'w-8/12'}
            estilosLista={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 border-cendiatra rounded-lg focus:outline-none  sm:text-sm border`}
            titulo={'Servicio'}
            opciones={listaDeServicios}
            valor={servicioSeleccionado.id}
            onChange={(e) => manejarExamenSeleccionado(e.target.value)}
          />
          <NumeroConTitulo
            titulo={'Cantidad'}
            formatoMoneda={false}
            estilosContenedor={'w-1/12 mt-2'}
            estilosAdicionales={`w-12 h-10 border border-cendiatra rounded focus:outline-none mx-5 ${
              servicioSeleccionado?.nombreServicio
                ? ''
                : 'bg-cendiatra-gris-2 text-white pointer-events-none placeholder-white'
            }`}
            funcion={manejarCantidadDeServicios}
            valor={cantidadServicios}
          />
          <NumeroConTitulo
            titulo={'Valor Unidad'}
            estilosContenedor={'w-1/12 mt-2'}
            estilosAdicionales={
              'w-12 h-10 focus:outline-none pointer-events-none'
            }
            valor={servicioSeleccionado?.valorUnidad}
          />
          <NumeroConTitulo
            titulo={'Valor Total'}
            estilosContenedor={'w-1/12 mt-2'}
            estilosAdicionales={
              'w-12 h-10 focus:outline-none pointer-events-none'
            }
            valor={servicioSeleccionado?.valorTotal}
          />
          <NumeroConTitulo
            titulo={'Aplicar IVA'}
            estilosContenedor="w-1/12 mt-2"
            estilosAdicionales="w-12 h-10 pointer-events-none"
            formatoMoneda={false}
            valor={
              servicioSeleccionado?.iva
                ? `IVA ${servicioSeleccionado?.iva}%`
                : ''
            }
            placeholder={''}
          />
          <BotonConImagen
            estilosContenedor={'mt-5 -mr-10 w-1/12'}
            ancho={'w-full flex justify-center items-center '}
            imagen={BotonAgregar}
            textoAlternativo={'Agregar'}
            funcionAEjecutar={agregarServicio}
          />
        </div>
        {servicioSeleccionado?.nombreServicio
          .toUpperCase()
          .includes('TIQUETERA') ? (
          <div className="w-full flex items-center justify-start my-2 flex-wrap">
            <div className="w-2/12 flex justify-start items-center">
              <LeyendaConInputRadio
                titulo={'Enviar a domicilio'}
                seleccionado={radialSeleccionado}
                funcion={cambiarInputRadial}
              />
            </div>
            <div className="w-2/12 flex justify-start items-center">
              <LeyendaConInputRadio
                titulo={'Recoger en sede'}
                seleccionado={!radialSeleccionado}
                funcion={cambiarInputRadial}
              />
            </div>
            {radialSeleccionado ? (
              <div className="w-8/12 text-cendiatra-rojo-1">
                "Estimado cliente, solo tenemos disponibilidad en Bogotá, Cali y
                Medellín”,
              </div>
            ) : null}

            <div
              className={`w-full  items-center justify-start my-5 ${
                radialSeleccionado ? 'flex' : 'hidden'
              }`}
            >
              <Controller
                name="nombreDeQuienRecibe"
                control={control}
                rules={{
                  required: true,
                  onChange: (e) =>
                    agregarValoresTiquetera(e.target.value, e.target.name),
                }}
                render={({
                  field: {name, value, onChange},
                  fieldState: {error},
                }) => (
                  <Input
                    estilosContenedor="w-15% mr-5"
                    estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                      !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                    }`}
                    mostrarErrores={true}
                    tipo="text"
                    nombre={name}
                    titulo={'Nombre de quien recibe'}
                    valor={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="ciudadId"
                control={control}
                rules={{
                  required: true,
                  onChange: (e) =>
                    agregarValoresTiquetera(e.target.value, e.target.name),
                }}
                render={({
                  field: {name, value, onChange},
                  fieldState: {error},
                }) => (
                  <ListaDesplegable
                    funcion={null}
                    estilosContenedor="w-15% mr-5"
                    estilosLista={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                      !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                    }`}
                    nombre={name}
                    titulo={'Ciudad'}
                    opciones={CiudadesPermitidas}
                    valor={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="barrio"
                control={control}
                rules={{
                  required: true,
                  onChange: (e) =>
                    agregarValoresTiquetera(e.target.value, e.target.name),
                }}
                render={({
                  field: {name, value, onChange},
                  fieldState: {error},
                }) => (
                  <Input
                    estilosContenedor="w-15% mr-5"
                    estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                      !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                    }`}
                    mostrarErrores={true}
                    tipo="text"
                    nombre={name}
                    titulo={'Barrio'}
                    valor={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="direccion"
                control={control}
                rules={{
                  required: true,
                  onChange: (e) =>
                    agregarValoresTiquetera(e.target.value, e.target.name),
                }}
                render={({
                  field: {name, onChange, value},
                  fieldState: {error},
                }) => (
                  <Input
                    estilosContenedor="w-15% mr-5"
                    estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                      !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                    }`}
                    mostrarErrores={true}
                    tipo="text"
                    nombre={name}
                    titulo={'Dirección a entregar'}
                    valor={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="telefono"
                control={control}
                rules={{
                  required: true,
                  onChange: (e) =>
                    agregarValoresTiquetera(e.target.value, e.target.name),
                }}
                render={({
                  field: {name, onChange, value},
                  fieldState: {error},
                }) => (
                  <Input
                    estilosContenedor="w-15% mr-5"
                    estilosInput={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm border ${
                      !error ? 'border-cendiatra' : 'border-cendiatra-rojo-1'
                    }`}
                    mostrarErrores={true}
                    tipo="text"
                    nombre={name}
                    titulo={'Teléfono'}
                    valor={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>

            <div
              className={`w-full ${
                !radialSeleccionado ? 'flex' : 'hidden'
              } items-center justify-start my-5`}
            >
              <div className="w-2/12 flex justify-start items-center">
                <ListaDesplegable
                  estilosContenedor="w-full mr-5"
                  estilosLista={`rounded relative block w-full p-1.5 border text-cendiatra-gris-3 rounded-lg focus:outline-none  border border-cendiatra ${
                    sedeSeleccionada?.id
                      ? 'border-cendiatra'
                      : 'border-cendiatra-rojo-1'
                  }`}
                  titulo={'Sede donde recoger'}
                  opciones={todasLasSedes}
                  valor={sedeSeleccionada?.id}
                  nombre={'sedeParaRecoger'}
                  onChange={(e) => manejarSedeSeleccionada(e.target.value)}
                />
              </div>
              <div className="w-5/12 flex justify-start items-center flex-wrap mt-1">
                <span className="w-full text-cendiatra mb-1">
                  Dirección donde recoger
                </span>
                <span className="w-full text-cendiatra-gris-1 py-1.5 text-md">
                  {sedeSeleccionada?.direccion}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </ContenedorTituloResaltado>
      <ContenedorTituloResaltado
        titulo={'SERVICIOS EN EL CARRITO'}
        activarBorde={false}
        imagen={IconoServicioEnCarrito}
      >
        <div className="w-full flex items-center justify-center -mt-14">
          <TablaDinamica
            columns={columns}
            data={datosTablaServicios}
            fondoTabla={'hidden h-1'}
          />
        </div>

        <div className="w-full flex justify-between items-center mt-10">
          <div className="w-8/12"></div>
          <div className="w-4/12 flex justify-end items-center flex-wrap">
            <span className="w-full text-cendiatra items-center justify-center text-lg font-bold">
              Servicios : {aplicarFormatoMoneda(valoresTabla?.totalServicios)}
            </span>
            <span className="w-full text-cendiatra items-center justify-center text-lg font-bold">
              Iva : {aplicarFormatoMoneda(valoresTabla?.totalIva)}
            </span>
            <span className="w-full text-cendiatra items-center justify-center text-xl font-bold mt-5">
              Total : {aplicarFormatoMoneda(valoresTabla?.totalTransaccion)}
            </span>
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-10">
          <BotonSimple
            texto={'Continuar'}
            estilosBoton={`w-28 h-10 text-white ${
              datosTablaServicios.length > 0
                ? 'bg-fondoBoton'
                : 'bg-cendiatra-gris-placeholder pointer-events-none'
            }  bg-center bg-cover bg-no-repeat rounded-lg text-14px m-2`}
            funcion={() =>
              funcionCambiarDePantalla(
                valoresTabla,
                datosTablaServicios,
                datosTiqueteras,
                sedeSeleccionada
              )
            }
            tipoDeBoton={'button'}
          />
        </div>
      </ContenedorTituloResaltado>
    </>
  )
}

export default PagarOtrosServicios
