import React, {useContext, useState, useEffect} from 'react'
import iconoClip from '../../imagenes/Clip.svg'
import Excelok from '../../imagenes/DocExcel.svg'
import {useModal} from '../../hooks/useModal'
import {CargarArchivoOrdenesPorEmpresaId} from '../../microservicios'
import {ContextoAplicacion} from '../../contexto'
import {useObtenerInformacion} from '../../hooks/useInformacion'
import {useHistory} from 'react-router-dom'
import { RUTAS_APLICACION } from '../../constantes'

const CargaArchivo = () => {
  const contextoApp = useContext(ContextoAplicacion)
  const [cerrarModal] = useModal()

  const [nombreArchivo, setNombreArchivo] = useState('')
  const [archivo, setArchivo] = useState(null)

  const informacion = useObtenerInformacion()

  const history = useHistory()

  const {city} = informacion

  const empresaId = city

  const excelFile =
    'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

  const cargarArchivoInput = (e) => {
    const archivo = e.target.files[0]

    var reader = new FileReader()
    reader.readAsDataURL(archivo)

    reader.onload = async function (e) {
      let formData = new FormData()

      formData.append('empresaId', empresaId)
      formData.append('ordenes', archivo)
      formData.append('NombreArchivo', archivo.name)

      setNombreArchivo(archivo.name)

      setArchivo(formData)
    }
  }

  const cargarArchivo = async (data) => {
    try {
      let respuesta = await CargarArchivoOrdenesPorEmpresaId(data)

      if (respuesta.status === 200 || respuesta.status === 201) {
        modalExitosa(respuesta.data.cantidadOrdenes)
      }
    } catch (error) {
      if (typeof error.response.data !== 'string') {
        cerrarModal()
        modalErrores(error?.response?.data)
      } else {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (archivo) {
      cargarArchivo(archivo)
    }
  }, [archivo])

  const modalErrores = (error) => {
    contextoApp.setModal({
      abierto: true,
      titulo: (
        <span className="font-bold">
          <p className="text-red-500 text-xl mb-10 2xl">
            EL ARCHIVO TIENE ERRORES
          </p>
        </span>
      ),
      contenido: (
        <div className="w-full mb-10 text-cendiatra">
          {error.errores.map((error, index) => (
            <div key={index}>
              <p className="text-cendiatra normal-case">
                <span className="font-bold">DEBE VERIFICAR LA FILA :</span>{' '}
                <span className="text-red-500">{error.fila} </span>{' '}
                <span className="font-bold ml-2"> COLUMNA : </span>
                <span className="text-red-500"> {!!error.columna ? error.columna : error.descripcion} </span>
              </p>
            </div>
          ))}
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar',
          click: () => {
            limpiarArchivo()
          },
        },
      ],
    })
  }

  const limpiarArchivo = () => {
    setNombreArchivo('')
    setArchivo(null)
    cerrarModal()
  }

  const manejarVolverAOrdenes = () => {
    cerrarModal()
    history.push(RUTAS_APLICACION.ORDENES)
  }

  const modalExitosa = (cantidadOrdenes) => {
    contextoApp.setModal({
      abierto: true,
      titulo: '',
      contenido: (
        <>
          <div className="w-full text-cendiatra  font-bold normal-case text-2xl mb-10 ">
            CARGADO EXITOSAMENTE
          </div>
          <div className="w-full">
            <p className="text-cendiatra text-17px font-bold">{cantidadOrdenes}</p>
            <p className="text-cendiatra">ÓRDENES CARGADAS</p>
          </div>
        </>
      ),
      botones: [
        {
          nombre: 'Volver',
          click: () => {
            limpiarArchivo()
          },
        },
        {
          nombre: 'Ver Órdenes Cargadas',
          click: () => {
            manejarVolverAOrdenes()
          },
        },
      ],
    })
  }

  return (
    <div className="w-full flex justify-evenly items-center text-cendiatra flex-col">
      <div className="w-full flex justify-center items-center mx-auto text-center border border-gray-300 rounded-lg p-5">
        <p className="w-full flex text-17px">
          CARGAR ARCHIVO DE ORDENES MASIVAS
        </p>

        <div className="w-full flex justify-center border border-gray-300 rounded-lg p-3 mt-3 relative">
          {!!nombreArchivo ? (
            <span
              onClick={limpiarArchivo}
              className="text-red-500 border border-red-500 rounded-full w-1 h-1 p-3 flex justify-center items-center cursor-pointer absolute top-0 right-0 mt-2 mr-2 z-10"
            >
              X
            </span>
          ) : null}

          <label
            className="flex flex-col items-center cursor-pointer text-center text-gray-500"
            htmlFor="archivo"
          >
            {!nombreArchivo ? (
              <>
                <span> Seleccionar archivo</span>
                <img className="mt-3" src={iconoClip} alt="clip" width={20} />
              </>
            ) : (
              <>
                <img className="mt-3" src={Excelok} alt="excel" width={40} />
                <span>{nombreArchivo}</span>
              </>
            )}
          </label>

          <input
            className="hidden"
            id="archivo"
            type="file"
            accept={excelFile}
            onChange={(e) => cargarArchivoInput(e)}
            onClick={(e) => (e.target.value = null)}
          />
        </div>
      </div>
    </div>
  )
}

export default CargaArchivo
