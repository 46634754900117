import React from 'react'

const CeldaTextoEstilosEditables = ({texto, estilosTexto}) => {
  return (
    <div className={`w-full flex justify-center items-center ${estilosTexto}`}>
      <span className="w-auto">{texto}</span>
    </div>
  )
}

export default CeldaTextoEstilosEditables
