import React, {useContext} from 'react'
import {BotonSimple} from '../Botones'
import CargaArchivo from './CargaArchivo'
import descargarPlantillaimg from '../../imagenes/DescargarPlantilla.svg'
import {useObtenerInformacion} from '../../hooks/useInformacion'
import {DescargaPlantillaOrdenesPorEmpresaId} from '../../microservicios'
import moment from 'moment'
import {ContextoAplicacion} from '../../contexto'
import marcadoX from '../../imagenes/MarcadodeX.gif'

const CargaArchivoOrdenMasiva = () => {
  const contextoAplicacion = useContext(ContextoAplicacion)
  const informacion = useObtenerInformacion()

  const {city} = informacion

  const empresaId = city

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const descargarPlantilla = async () => {
    try {
      const respuesta = await DescargaPlantillaOrdenesPorEmpresaId(empresaId)
      if (respuesta.status === 200) {
        const url = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${respuesta.data}`
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `PlantillaCargaOrdenes_${empresaId}_${moment().format(
            'DDMMYYYYHHmmss'
          )}.xlsx`
        )
        document.body.appendChild(link)
        link.click()
        contextoAplicacion.setModal({
          abierto: true,
          titulo: <span className="!text-red-700">NOTA</span>,
          contenido: (
            <>
              <div className="text-center w-full mb-10">
                <p className="text-cendiatra text-xl font-semibold uppercase">
                  Para seleccionar los paquetes y/o servicios en la orden,{' '}
                  <br />
                  debe ingresarle una "X" en la celda.
                </p>
              </div>
              <div className="w-full flex justify-center">
                <img src={marcadoX} className='w-[60%]' />
              </div>
            </>
          ),
          botones: [
            {
              nombre: 'Entendido',
              click: manejarCerrarModal,
            },
          ],
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <BotonSimple
        texto={'Descargar Plantilla'}
        estilosBoton={`w-52 h-12 p-1.5 gap-2 flex items-center justify-center rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px`}
        funcion={descargarPlantilla}
        icono={descargarPlantillaimg}
      />

      <CargaArchivo />
    </>
  )
}

export default CargaArchivoOrdenMasiva
