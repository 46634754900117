import React, {useEffect} from 'react'
import AlertaDinamica from './AlertaDinamica'
import {
  NUMERO_CARACTERES_DOCUMENTO,
  DOCUMENTOS_NUMERO_STRING,
  LONGITUD_CARACTERES,
} from '../../constantes'

const AlertaLongitudDocumento = ({
  tipoDocumento,
  numeroDocumento,
  setInformacionCaracteres,
  errors,
  informacionCaracteres,
}) => {
  useEffect(() => {
    if (tipoDocumento) {
      const LongitudCampo = NUMERO_CARACTERES_DOCUMENTO[tipoDocumento]

      const validarTipoDocumento = {
        ...LongitudCampo,
        maximo: validarNumeroCaracteresCedula(LongitudCampo.maximo),
      }
      setInformacionCaracteres(validarTipoDocumento)
    }
  }, [tipoDocumento, numeroDocumento])

  const validarNumeroCaracteresCedula = (longitud) => {
    if (
      tipoDocumento.toString() === DOCUMENTOS_NUMERO_STRING.CEDULA_CIUDADANIA &&
      numeroDocumento?.length === 9
    ) {
      return LONGITUD_CARACTERES.LONGITUD_OCHO_CARACTERES
    }
    return longitud
  }

  const validarNumeroCaracteres = (nombreCampo) => {
    const nombresErrores = ['minLength', 'maxLength']

    return nombresErrores.includes(errors[nombreCampo]?.type) && tipoDocumento
  }

  return (
    <AlertaDinamica
      mostrarAlerta={validarNumeroCaracteres('numeroDocumento')}
      mensaje={informacionCaracteres.mensaje}
      background="bg-gray-200"
      color="text-red-800"
    />
  )
}

export default AlertaLongitudDocumento
