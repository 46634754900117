export const RUTAS_APLICACION = {
  ORDENES: '/ordenes',
  GESTION_ATENCIONES: '/gestion-atenciones',
  CAMBIAR_CONTRASEÑA: '/cambioContrasena',
  AYUDA: '/ayuda',
  RESULTADOS_PACIENTE: '/resultadospaciente',
  CREAR_ORDEN: '/crearorden',
  EDITAR_ORDEN: '/editarorden',
  CARGA_MASIVA_ORDEN: '/cargamasivaorden',
  RECARGAR_SALDO: '/recargarsaldo',
  FINALIZAR_RECARGA: '/finalizar-recarga',
  FINALIZAR_RECARGA_OTROS_SERVICIOS: '/finalizar-pago-otros-servicios',
  VER_DETALLE_Y_MOVIMIENTO: '/verdetalleymovimiento',
  PAGAR_OTROS_SERVICIOS: '/pagar-otros-servicios',
  DESCARGA_MASIVA: '/descarga-masiva',
  HOME: '/home',
  MAIN: '/',
}
