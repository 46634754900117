import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {MsalProvider} from '@azure/msal-react'
import {Provider} from './contexto/ContextoAplicacion'
import AxiosInterceptador from './interceptador/AxiosInterceptador'
import moment from 'moment'
import 'moment/locale/es'
import {getMsalInstance, initializeMsal} from './msalService'

moment.locale('es')

initializeMsal()
  .then(() => {
    const msalInstance = getMsalInstance()

    ReactDOM.render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <Provider>
            <App />
          </Provider>
        </MsalProvider>
      </React.StrictMode>,
      document.getElementById('root')
    )
  })
  .catch((error) => {
    console.error('Failed to load app configuration', error)
    // Manejo del error o mostrar una pantalla de error
  })
