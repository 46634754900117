import {useState} from 'react'
import HojaInformacion from '../../imagenes/HojaInformacion.svg'
import {CeldaDobleTexto} from '../Tablas'
import {Tooltip} from '../Tooltip'

const CeldaInformacionOrdenYUsuario = ({row: {original}}) => {
  const [mostrarTooltip, setMostrarTooltip] = useState(false)

  return (
    <div className="w-full flex">
      <div className="w-1/3">
        <div
          className={`w-full ${
            original?.idOrdenPortalCliente !== 0 ? 'flex' : 'hidden'
          }`}
        >
          <Tooltip
            tooltip={
              <div className="w-full flex flex-wrap justify-evenly px-2">
                <span className="w-full flex">
                  Número de orden: {original?.idOrdenPortalCliente ?? ''}
                </span>
                <span className="w-full flex">
                  Fecha de orden:{' '}
                  {original?.numeroOrdenyFechaCreacion?.Fila2 ?? ''}
                </span>
              </div>
            }
            activo={mostrarTooltip}
          >
            <button
              onMouseEnter={() => setMostrarTooltip(true)}
              onMouseLeave={() => setMostrarTooltip(false)}
            >
              <img
                src={HojaInformacion}
                alt="informacioToolTip"
                className="w-12 h-9"
              />
            </button>
          </Tooltip>
        </div>
      </div>
      <div className="w-2/3 flex items-center">
        <CeldaDobleTexto value={original?.nombresyDocumento ?? {}} />
      </div>
    </div>
  )
}

export default CeldaInformacionOrdenYUsuario
