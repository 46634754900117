import React, {useContext} from 'react'
import {ContextoAplicacion} from '../../contexto'
import Modal from './Modal'

const ModalDinamico = () => {
  const contexto = useContext(ContextoAplicacion)
  return (
    <Modal open={contexto.modal.abierto}>
      <div className="w-full flex flex-wrap justify-center items-center">
        <div className="w-full m-2">
          <span className="text-cendiatra text-3xl font-bold">
            {contexto.modal.titulo}
          </span>
        </div>
        <div className="w-full flex flex-wrap m-2">
          {contexto.modal.contenido}
        </div>
        <div className="w-full flex justify-evenly items-center m-5">
          {contexto.modal.botones.map((boton) => (
            <button
              key={boton.nombre}
              className="w-40 h-14 rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white text-lg p-1 leading-20px"
              type="button"
              onClick={boton.click}
            >
              {' '}
              {boton.nombre}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  )
}
export default ModalDinamico
