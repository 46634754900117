import {getRestApi} from './ApiAxios'

export const ObtenerExamenesMasFrecuentesPorEmpresaId = async (id) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'atencion-function/ObtenerExamenesMasFrecuentesPorEmpresaId?empresaId=' + id
  )
}

export const ObtenerFacturadoAtencionesPorMesYEmpresaId = async (
  empresaId,
  periodo
) => {
  const restApi = await getRestApi()
  return await restApi.get(
    'atencion-function/ObtenerFacturadoAtencionesPorMesYEmpresaId',
    {
      params: {
        empresaId,
        periodo,
      },
    }
  )
}
