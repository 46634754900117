import React, {useState, useEffect, useContext} from 'react'
import {ContenedorPagina} from '../../componentes/Contenedores'
import PagarOtrosServicios from '../../componentes/usuarioContable/PagarOtrosServicios'
import moment from 'moment'
import {TEXTO_VISUAL} from '../../constantes'
import OtrosServiciosRecargarCuentaServicios from '../../componentes/usuarioContable/OtrosServiciosRecargarCuentaServicios'
import {useHistory} from 'react-router-dom'
import {useMsal} from '@azure/msal-react'
import {obtenerUrlRedirect} from '../../config'
import {ServicioPagarOtrosServicios} from '../../microservicios/OtrosServicios'
import clsx from 'clsx'
import {ContextoAplicacion} from '../../contexto'

const OtrosServicios = () => {
  const contextoAplicacion = useContext(ContextoAplicacion)

  const [mostrarPagoTransaccion, setMostrarPagoTransaccion] = useState(true)
  const [valorServicios, setValrServicios] = useState({})
  const [informacionServicio, setInformacionServicio] = useState({})
  const [informacionTiqueteras, setInformacionTiqueteras] = useState({})
  const [informacionSede, setInformacionSede] = useState([])
  const [urlApp, setUrlApp] = useState('')
  const history = useHistory()
  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [ip, setIp] = useState('')

  useEffect(() => {
    const obtenerDireccionIP = () => {
      fetch('https://api.ipify.org?format=json')
        .then((response) => response.json())
        .then((data) => {
          setIp(data.ip)
        })
        .catch((error) => {
          console.error(error)
        })
    }

    obtenerDireccionIP()
  }, [])

  useEffect(() => {
    obtenerUrlRedirect()
      .then((response) => {
        setUrlApp(response)
      })
      .catch((err) => {
        console.error('no se pudo obtener la url de redireccion', err)
      })
  }, [])

  const abrirPagoTransaccion = (
    valores,
    infoServicio,
    infoTiqueteras,
    infoSede
  ) => {
    if (valores) {
      setMostrarPagoTransaccion((prev) => !prev)
      setValrServicios(valores)
      setInformacionServicio(infoServicio)
      setInformacionTiqueteras(infoTiqueteras)
      setInformacionSede(infoSede)
      return
    }
    setMostrarPagoTransaccion((prev) => !prev)
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const crearPayloadOtrosServicios = (
    datosTransaccion,
    datosServicio,
    datosTiquetera,
    datosSede,
    valoresTabla,
    municipio,
    totalTabla
  ) => {
    const payload = {
      empresaId: idEmpresa,
      direccion: datosTiquetera?.direccion,
      ciudadId: municipio?.Id,
      barrio: datosTiquetera?.barrio,
      telefono: datosTiquetera?.telefono,
      nombre: datosTiquetera?.nombreDeQuienRecibe,
      envioDomicilio: datosTiquetera?.envioDomicilio,
      sedeId: datosSede?.id,
      porcentajeRenta: valoresTabla?.retencionRenta,
      porcentajeICA: valoresTabla?.retencionICA,
      porcentajeBomberil: valoresTabla?.sobretasaBomberil,
      porcentajeIVA: valoresTabla?.retencionIVA,
      otrosServicios: datosServicio?.map((servicio) => {
        return {
          otroServicioId: servicio?.id,
          cantidad: servicio?.numeroDeServicios,
        }
      }),
      valor: totalTabla,
      detalleRecarga: '',
      ip: ip,
      UrlCompletaRetorno: `${urlApp}/finalizar-pago-otros-servicios`,
      esPortalLite: false,
    }
    abrirPasarelaDePagos(payload)
  }

  const abrirPasarelaDePagos = async (payload) => {
    try {
      const respuesta = await ServicioPagarOtrosServicios(payload)
      if (respuesta.data) {
        sessionStorage.setItem('transaccionId', respuesta?.data?.transaccionId)
        const urlAvalPay = respuesta?.data?.urlPasarelaPagos
        window.open(urlAvalPay, '_self')
      }
    } catch (error) {
      contextoAplicacion.setModal({
        abierto: true,
        titulo: 'CONEXIÓN FALLIDA',
        contenido: (
          <>
            <div className="text-center w-full flex justify-center items-center mb-6 mt-5">
              <div className="w-10/12">
                <p className="text-cendiatra  text-2xl">
                  No se pudo establecer conexión con la pasarela de pago.
                </p>
                <p className="text-cendiatra text-2xl w-full">
                  Por favor, intente más tarde.
                </p>
              </div>
            </div>
          </>
        ),
        botones: [
          {
            nombre: 'Regresar',
            click: manejarCerrarModal,
          },
        ],
      })
      console.log(error)
    }
  }

  return (
    <ContenedorPagina
      tituloPagina={
        <>
          <h4 className="w-full text-center text-cendiatra text-2xl font-bold">
            {TEXTO_VISUAL.OTROS_SERVICIOS.TITULO}
          </h4>
          <span className="w-full flex justify-center items-center text-cendiatra text-xl font-bold">
            {TEXTO_VISUAL.OTROS_SERVICIOS.SUBTITULO}
          </span>
          <div className="w-full flex items-center justify-start mt-10 -ml-20">
            <span>Fecha: {moment().format('DD/MM/YYYY')}</span>
          </div>
        </>
      }
      estilosAdicionales="-mt-10"
    >
      <div className="w-full flex justify-center items-center flex-wrap">
        <div
          className={clsx(
            !mostrarPagoTransaccion && 'hidden',
            'w-full flex flex-wrap'
          )}
        >
          <PagarOtrosServicios
            funcionCambiarDePantalla={abrirPagoTransaccion}
          />
        </div>

        <div
          className={clsx(
            mostrarPagoTransaccion && 'hidden',
            'w-full flex flex-wrap'
          )}
        >
          <OtrosServiciosRecargarCuentaServicios
            manejarGuardar={crearPayloadOtrosServicios}
            datosTransaccion={valorServicios}
            datosServicio={informacionServicio}
            datosTiquetera={informacionTiqueteras}
            datosSede={informacionSede}
            funcionRegresar={abrirPagoTransaccion}
          />
        </div>
      </div>
    </ContenedorPagina>
  )
}

export default OtrosServicios
