import {useContext, useEffect, useState} from 'react'
import {useMsal} from '@azure/msal-react'
import {
  ObtenerMovimientosPendientesPorEmpresaId,
  ValidarEstadoTransaccion,
} from '../../microservicios'
import {ContextoAplicacion} from '../../contexto'
import {ESTADOS_TRANSACCION_MOVIMIENTOS} from '../../constantes'
import {TIPOS_FACTURACION} from '../../constantes'

const useValidacionTransaccionPendiente = () => {
  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [ip, setIp] = useState('')
  const [ipReady, setIpReady] = useState(false)
  const contextoAplicacion = useContext(ContextoAplicacion)

  const obtenerDireccionIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json')
      const data = await response.json()
      setIp(data.ip)
      setIpReady(true)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    obtenerDireccionIP()
  }, [])
  useEffect(() => {
    const obtenerTransaccionesPendientes = async () => {
      if (
        contextoAplicacion?.infoEmpresa?.tipoFacturacion ===
        TIPOS_FACTURACION.CONTADO
      ) {
        try {
          const response = await ObtenerMovimientosPendientesPorEmpresaId(
            idEmpresa
          )
          if (response.status === 204) {
            return
          }
          response.data.forEach(async (elemento) => {
            await estadoTransaccion(
              elemento.transaccionId,
              elemento.tipoMovimiento,
              elemento.estadoMovimiento
            )
          })
        } catch (error) {
          console.error(error)
        }
      }
    }

    obtenerTransaccionesPendientes()
  }, [ipReady])

  const estadoTransaccion = async (
    transaccionId,
    tipoMovimiento,
    estadoMovimiento
  ) => {
    try {
      if (
        tipoMovimiento === ESTADOS_TRANSACCION_MOVIMIENTOS.PENDIENTE &&
        estadoMovimiento === ESTADOS_TRANSACCION_MOVIMIENTOS.PENDIENTE &&
        transaccionId !== null &&
        ipReady
      ) {
        await ValidarEstadoTransaccion(idEmpresa, transaccionId, ip)
      }
    } catch {
      contextoAplicacion.setModal({
        abierto: false,
        titulo: '',
        contenido: '',
        botones: [],
      })
    }
  }

  return null
}

export default useValidacionTransaccionPendiente
