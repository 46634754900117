import BotonQuitar from '../../imagenes/botonQuitar.svg'
import {BotonConImagen} from '../Botones'

const TablaServiciosSeleccionados = ({
  serviciosSeleccionados,
  quitarServicioSeleccionado,
}) => {
  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <table className="w-full">
        <thead
          className="w-full flex justify-evenly items-center bg-cendiatra-verde-7 text-white h-14 rounded-10px"
          style={{lineHeight: '16px'}}
        >
          <th>EXÁMENES INCLUIDOS EN LA ORDEN PARA REALIZAR</th>
        </thead>
        <tbody
          className="bg-grey-light flex flex-col overflow-y-scroll w-full border border-cendiatra rounded"
          style={{height: '280px'}}
        >
          {serviciosSeleccionados?.map((item, key) => {
            return (
              <tr
                className="w-full text-cendiatra-gris-1 rounded-2xl flex text-center text-13px px-5 py-1.5 border"
                key={key}
              >
                <td className="text-cendiatra-gris-1 flex w-full">
                  <div className="w-11/12 text-left">
                    <span className="w-full">
                      {item.Nombre?.toUpperCase() ?? ''}
                    </span>
                    <br />
                    <div className="w-full flex justify-center">
                      <ul className="list-disc w-11/12">
                        {item?.hasOwnProperty('ServiciosPaquete')
                          ? item.ServiciosPaquete?.map((servicio, key) => (
                              <li
                                key={key}
                                className={'text-13px text-cendiatra'}
                              >
                                {servicio.Nombre?.toUpperCase() ?? ''}
                              </li>
                            ))
                          : ''}
                      </ul>
                    </div>
                  </div>
                  <div className="W-1/12 cursor-pointer flex justify-center items-center">
                    <BotonConImagen
                      estilosContenedor={'w-full'}
                      imagen={BotonQuitar}
                      textoAlternativo={'quitar'}
                      funcionAEjecutar={() => quitarServicioSeleccionado(item)}
                      estilosImagen={'h-5 w-5'}
                    />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default TablaServiciosSeleccionados
