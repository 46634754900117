import React from 'react'
import ReactDom from 'react-dom'

const MODAL_STYLES = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  padding: '50px',
  zIndex: 1000,
  //   border: "1px solid",
  borderRadius: '2%',
  textAlign: 'center',
}

const OVERLAY_STYLE = {
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  rigth: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, .7)',
  zIndex: 1000,
}
const Modal = ({open, children}) => {
  if (!open) return null
  return ReactDom.createPortal(
    <>
      <div style={OVERLAY_STYLE}>
        <div
          className="border border-cendiatra w-4/5 lg:w-3/5"
          style={MODAL_STYLES}
        >
          {children}
        </div>
      </div>
    </>,
    document.getElementById('portal')
  )
}

export default Modal
