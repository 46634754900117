/** Componente que representa el título para una gráfica. */
export const TituloGrafica = ({titulo, tituloExtra}) => {
  return (
    <div className="flex items-center text-17px text-cendiatra-gris-1 my-5 border-b-2 w-11/12">
      <p className="pb-5">
        {titulo}
        {typeof tituloExtra === 'string' ? (
          <span className="ml-1 text-cendiatra-verde-2">{tituloExtra}</span>
        ) : (
          tituloExtra
        )}
      </p>
    </div>
  )
}
