export const ContenedorTituloResaltado = ({
  flexWrap = true,
  margenContenedor = 'mt-4',
  paddingContenedor = 'py-3 px-2',
  titulo,
  children,
  activarBorde = true,
  imagen = null,
}) => {
  return (
    <div
      className={`flex flex-col w-full ${
        activarBorde ? ' border border-cendiatra' : ''
      }  rounded-xl ${margenContenedor}`}
    >
      <div className="flex w-full bg-cendiatra rounded-xl items-center justify-center p-2 text-white">
        <img
          className={`w-7 h-7 mr-2 ${imagen ? 'flex' : 'hidden'}`}
          src={imagen}
          alt={'imagenBarra'}
        />{' '}
        {titulo}
      </div>
      <div
        className={`flex ${
          flexWrap ? 'flex-wrap' : 'flex-nowrap'
        } w-full ${paddingContenedor}`}
      >
        {children}
      </div>
    </div>
  )
}

export default ContenedorTituloResaltado
