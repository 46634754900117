import React, {useContext, useEffect, useState} from 'react'
import {Input, AreaDeTexto, InputMoneda, InputConResultado} from '../inputs'
import {BotonSimple} from '../Botones'
import {ContextoRecargarSaldo} from '../../contexto'
import {useMsal} from '@azure/msal-react'
import {ObtenerEmpresaPorId} from '../../microservicios'
import {useForm, Controller} from 'react-hook-form'
import {CampoFiltrar} from '../inputs'
import {FiltrarCiudadPorNombre} from '../../cache/servicios-cache'
import {ContenedorTituloResaltado} from '../Contenedores'
import {aplicarFormatoMoneda} from '../../utils'
import {AlertaDinamica} from '../MostrarEnPantalla'

const FormularioRecargarCuentaServicios = ({manejarGuardar}) => {
  const contextoRecargarSaldo = useContext(ContextoRecargarSaldo)

  const {accounts} = useMsal()

  const valoresTablaPorDefecto = {
    montoRecarga: '',
    retencionRenta: '',
    rentaTotal: '',
    retencionICA: '',
    ICATotal: '',
    sobretasaBomberil: '',
    bomberilTotal: '',
    tarifaIVA: '',
    totalIVA: '',
    valorRecibido: '',
  }
  const [valoresTabla, setValoresTabla] = useState(valoresTablaPorDefecto)
  const [alertaICA, setAlertaICA] = useState(false)
  const [totalTabla, setTotalTabla] = useState(0)
  const [municipiosFiltrados, setMunicipiosFiltrados] = useState([])
  const [municipio, setMunicipio] = useState({})

  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const {
    trigger,
    control,
    setValue,
    clearErrors,
    setError,
    formState: {errors, isValid},
  } = useForm({
    mode: 'onChange',
  })

  useEffect(() => {
    trigger()
    obtenerDatosEmpresa()
  }, [])

  const obtenerDatosEmpresa = async () => {
    ObtenerEmpresaPorId(idEmpresa).then((res) => {
      contextoRecargarSaldo.setFormularioRecargarSaldo({
        ...contextoRecargarSaldo.formularioRecargarSaldo,
        identificacionCliente: res?.data?.nit,
      })
      contextoRecargarSaldo.setDataPdf({
        documento: res?.data?.nit,
        nombre: res?.data?.nombre,
        direccion: res?.data?.direccion,
        telefono: res?.data?.telefono,
      })
    })
  }

  const calcularRetencionRenta = (valor) => {
    if (valor && valoresTabla?.montoRecarga) {
      const total = (valoresTabla?.montoRecarga * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: isNaN(total) ? '' : total,
        }
      })
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: '',
        }
      })
    }
  }

  const calcularRetencionICA = (valor) => {
    if (valor && valoresTabla?.montoRecarga) {
      const total = (valoresTabla?.montoRecarga * valor) / 1000
      setValoresTabla((prev) => {
        return {
          ...prev,
          ICATotal: isNaN(total) ? '' : total,
        }
      })
      setAlertaICA(false)
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          ICATotal: '',
          bomberilTotal: '',
        }
      })
    }
  }

  const calcularSobretasaBomberil = (valor) => {
    if (!valoresTabla?.ICATotal && valor) {
      return setAlertaICA(true)
    }
    if (valor && valoresTabla?.montoRecarga) {
      const total = (valoresTabla?.ICATotal * valor) / 100
      setValoresTabla((prev) => {
        return {
          ...prev,
          bomberilTotal: isNaN(total) || !valoresTabla?.ICATotal ? '' : total,
        }
      })
      setAlertaICA(false)
    } else if (!valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          bomberilTotal: '',
        }
      })
      setAlertaICA(false)
    }
  }

  const calcularValoresEnTabla = (nombrePropiedad, valor) => {
    const funcionesPorPropiedad = {
      retencionRenta: () => calcularRetencionRenta(valor),
      retencionICA: () => calcularRetencionICA(valor),
      sobretasaBomberil: () => calcularSobretasaBomberil(valor),
    }
    if (nombrePropiedad in funcionesPorPropiedad) {
      funcionesPorPropiedad[nombrePropiedad]()
    }
  }
  const modificarValoresTabla = (nombrePropiedad, valor) => {
    setValoresTabla((prev) => {
      return {
        ...prev,
        [nombrePropiedad]: valor ?? '',
      }
    })
    calcularValoresEnTabla(nombrePropiedad, valor)
    if (nombrePropiedad === 'montoRecarga' && !valor) {
      setValoresTabla((prev) => {
        return {
          ...prev,
          rentaTotal: '',
          ICATotal: '',
          bomberilTotal: '',
        }
      })
    }
  }
  useEffect(() => {
    const calcularValorTotal = () => {
      const valoresNumericos = [
        valoresTabla?.montoRecarga,
        valoresTabla?.rentaTotal,
        valoresTabla?.ICATotal,
        valoresTabla?.bomberilTotal,
      ]
        .filter((valor) => valor !== '')
        .map((valor) => parseFloat(valor))
      if (valoresNumericos.length >= 1) {
        const resultado = valoresNumericos
          .slice(1)
          .reduce(
            (acumulador, valor) => acumulador - valor,
            valoresNumericos[0]
          )

        setValoresTabla((prev) => {
          return {
            ...prev,
            valorRecibido: resultado ?? '',
          }
        })

        return setTotalTabla(resultado)
      } else {
        return setTotalTabla(0)
      }
    }
    calcularValorTotal()
  }, [
    valoresTabla?.montoRecarga,
    valoresTabla?.rentaTotal,
    valoresTabla?.ICATotal,
    valoresTabla?.bomberilTotal,
  ])

  useEffect(() => {
    calcularRetencionRenta(valoresTabla?.retencionRenta)
    calcularRetencionICA(valoresTabla?.retencionICA)
    calcularSobretasaBomberil(valoresTabla?.sobretasaBomberil)
  }, [valoresTabla?.montoRecarga, valoresTabla?.ICATotal])

  const manejarCiudadCambiada = (e) => {
    setError('municipio', {type: 'require', message: ''})
    setValue('municipio', e.target.value)
    if (e.target.value.length >= 3) {
      FiltrarCiudadPorNombre(e.target.value).then((res) => {
        if (res) {
          setMunicipiosFiltrados(
            res.map((ciudad) => ({
              Id: ciudad?.Id,
              Nombre: ciudad?.Nombre,
            }))
          )
        }
      })
    } else {
      setMunicipiosFiltrados([])
      setMunicipio({})
    }
  }

  const manejarCiudadSeleccionada = (ciudadSeleccionada) => {
    setValue('municipio', ciudadSeleccionada?.Nombre)
    clearErrors('municipio')
    setMunicipio(ciudadSeleccionada)
  }

  const manejarCambioDetalle = (detalle) => {
    contextoRecargarSaldo.setFormularioRecargarSaldo({
      ...contextoRecargarSaldo.formularioRecargarSaldo,
      detalle: detalle,
    })
  }

  return (
    <>
      <div className="w-full flex justify-center items-center flex-wrap mt-20">
        <div className="w-1/2 flex justify-center items-center ">
          <Input
            estilosContenedor={'w-10/12'}
            onChange={null}
            estilosInput={
              'appearance-none rounded relative block  w-full  p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'date'}
            titulo={'Fecha'}
            valor={contextoRecargarSaldo.formularioRecargarSaldo.fecha}
            deshabilitado={true}
          />
        </div>
        <div className="w-1/2 flex justify-center items-center ">
          <Input
            estilosContenedor={'w-10/12'}
            onChange={null}
            estilosInput={
              'appearance-none rounded relative block w-full  p-1.5 border border-cendiatra bg-cendiatra-gris-placeholder text-white  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm '
            }
            tipo={'text'}
            titulo={'Identificación del cliente'}
            valor={
              contextoRecargarSaldo.formularioRecargarSaldo
                .identificacionCliente
            }
            deshabilitado={true}
          />
        </div>
      </div>
      <div className="w-full flex justify-center items-center flex-wrap mt-20 border border-cendiatra rounded-lg py-10">
        <div className="w-10/12 flex justify-center items-center flex-wrap ">
          <div className="w-11/12 flex justify-end items-center my-2 p-5">
            <div className="w-5/12 flex justify-end items-center">
              <span className="w-5/12 text-cendiatra-verde-3 font-bold text-lg text-right pr-5">
                Monto a recargar
              </span>
              <InputMoneda
                estilosContenedor={'w-7/12 flex  items-center'}
                estilosInput={`appearance-none rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-2 font-bold text-center rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm`}
                valor={valoresTabla.montoRecarga}
                formatoVacio={true}
                onValueChange={(e) =>
                  modificarValoresTabla('montoRecarga', e.floatValue)
                }
                titulo={''}
              />
            </div>
          </div>
          <div className="w-11/12 flex justify-end items-center my-2">
            <div className="w-full flex justify-end items-center border-2 border-cendiatra-gris-1 rounded-3xl p-5">
              <span className="w-7/12  text-cendiatra-gris-3 mr-3 text-xl ">
                *Comience a escribir el municipio desde donde está haciendo la
                recarga y al obtener resultados selecciónelo de la lista
                desplegable.
              </span>
              <div className="w-5/12 flex flex-wrap justify-end items-center">
                <span className="w-5/12 text-cendiatra-verde-3 font-bold text-lg text-right pr-5">
                  Municipio*
                </span>
                <Controller
                  name="municipio"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({field: {value}, fieldState: {error}}) => (
                    <CampoFiltrar
                      titulo={''}
                      estilosPersonalizados={'w-7/12'}
                      estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border ${
                        error ? 'border-cendiatra-rojo-1' : 'border-cendiatra'
                      } text-cendiatra-verde-2 font-bold text-center  rounded-lg focus:outline-none focus:ring-indigo-500  focus:z-10 sm:text-sm 
                  
                  `}
                      placeholder={'Autocompletar'}
                      tipo={'text'}
                      valorDelCampoFiltro={value}
                      desactivarOtroFiltro={''}
                      informacionFiltrada={municipiosFiltrados}
                      handleChange={manejarCiudadCambiada}
                      handleOptionChange={manejarCiudadSeleccionada}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <span className="w-8/12 my-3 text-center text-cendiatra-rojo-1 text-xl font-semibold">
            Es responsabilidad del cliente conocer e ingresar sus tarifas de
            retención. Recuerde que las retenciones se deben realizar con cada
            pago. (Art. 392 ET). Por favor, asesórese para diligenciar
            correctamente esta información.
          </span>
          <div className="w-10/12 flex justify-center items-center flex-wrap p-2 text-cendiatra font-bold">
            <ContenedorTituloResaltado
              titulo={'TARIFAS DE RETENCIÓN'}
              margenContenedor={'mb-20 border-none'}
              paddingContenedor={
                'w-full flex justify-center items-center flex-wrap '
              }
            >
              <span className="w-8/12 my-3 text-center text-cendiatra-gris-1 text-xl ">
                No olvide que la base para practicar la retención en la fuente,
                inicia a partir de 4 UVT ($ 188.000 COP año 2024)
              </span>
              <InputConResultado
                titulo={
                  'Tarifa de retención de Renta (% sobre valor de recarga)'
                }
                estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
                placeholder={'0,00%'}
                valor={valoresTabla.retencionRenta}
                funcion={(e) =>
                  modificarValoresTabla('retencionRenta', e.floatValue)
                }
                totalCalculado={valoresTabla.rentaTotal}
              />
              <div className="w-full flex justify-center items-center flex-wrap border-2 rounded-lg py-5 my-2">
                <InputConResultado
                  titulo={
                    'Tarifa de retención ICA (valor de recarga * tarifa / 1000)'
                  }
                  estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
                  placeholder={'0,00'}
                  valor={valoresTabla.retencionICA}
                  funcion={(e) =>
                    modificarValoresTabla('retencionICA', e.floatValue)
                  }
                  totalCalculado={valoresTabla.ICATotal}
                  descripcion={'Res. DDI-023769 Grandes contribuyentes Bogotá.'}
                  porcentaje={false}
                  prefix={''}
                />
                <InputConResultado
                  titulo={'Sobretasa Bomberil (% sobre el valor del ICA)'}
                  estilosInput={`appearance-none text-center rounded relative block w-full p-1.5 border border-cendiatra text-cendiatra-verde-3 font-bold text-14px rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10`}
                  placeholder={'0,00%'}
                  valor={valoresTabla.sobretasaBomberil}
                  funcion={(e) =>
                    modificarValoresTabla('sobretasaBomberil', e.floatValue)
                  }
                  totalCalculado={valoresTabla.bomberilTotal}
                />
                <div className="w-full flex justify-start items-center">
                  <div className="w-6/12"></div>
                  <div className="w-5/12">
                    <AlertaDinamica
                      mostrarAlerta={alertaICA}
                      mensaje={
                        'Se requiere la tarifa ICA para calcular Bomberil'
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-start items-center ml-2 my-2 border-b-2 pb-5">
                <InputConResultado
                  titulo={'Tarifa retención de IVA (% sobre el valor del IVA)'}
                  estilosTitulo={'text-cendiatra-gris-4'}
                  estilosInput={`appearance-none rounded relative block w-full text-center p-1.5 border border-cendiatra text-cendiatra-gris-3  rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-cendiatra focus:z-10 sm:text-sm 
                ${
                  false //Descativado hasta que desidan darle funcionalidad
                    ? 'text-cendiatra-gris-3'
                    : 'bg-cendiatra-gris-placeholder pointer-events-none text-white placeholder-white'
                }
                
                `}
                  valor={valoresTabla.tarifaIVA}
                  funcion={(e) =>
                    modificarValoresTabla('tarifaIVA', e.floatValue)
                  }
                  totalCalculado={valoresTabla.totalIVA}
                  porcentaje={false}
                  deshabilitarTab={true}
                />
              </div>
              <div className="w-full flex justify-end items-center my-2">
                <div className="w-3/12 flex justify-center ">
                  <span className=" text-cendiatra-verde-2 font-bold text-start text-2xl">
                    Valor a recibir
                  </span>
                </div>
                <div className="w-3/12 flex justify-center">
                  <span className="text-cendiatra-verde-2 font-bold text-center text-2xl">
                    {aplicarFormatoMoneda(totalTabla)}
                  </span>
                </div>
              </div>
              <div className="w-full flex justify-center items-center my-2">
                <span className=" text-cendiatra-gris-1 font-bold text-start text-lg">
                  Cendiatra no asume la responsabilidad de errores en la
                  información proporcionada por el cliente.
                </span>
              </div>
            </ContenedorTituloResaltado>
          </div>
        </div>

        <div className="w-full flex justify-center items-center">
          <AreaDeTexto
            estilosContenedor={`w-6/12`}
            estilosAreaDeTexto={` rounded relative block w-full  p-1.5 border border-cendiatra text-cendiatra-gris-3 rounded-lg focus:outline-none  sm:text-sm`}
            titulo={'Detalle de la recarga'}
            placeholder={'DESCRIPCIÓN (TEXTO LARGO)'}
            cambioValor={(e) => manejarCambioDetalle(e.target.value)}
            valor={contextoRecargarSaldo.formularioRecargarSaldo.detalle}
            rows={8}
          />
        </div>
        <div className="w-full flex justify-center items-center mt-10">
          <BotonSimple
            texto={'Continuar'}
            estilosBoton={`h-40px w-40 p-1.5 rounded-lg ${
              valoresTabla.montoRecarga &&
              Object.keys(errors).length === 0 &&
              !alertaICA
                ? 'bg-cendiatra'
                : 'bg-cendiatra-gris-2 pointer-events-none'
            } bg-cendiatra bg-cover bg-no-repeat bg-center text-white font-bold text-14px`}
            funcion={() => manejarGuardar(valoresTabla, municipio, totalTabla)}
            deshabilitado={
              !valoresTabla.montoRecarga ||
              Object.keys(errors).length !== 0 ||
              alertaICA
            }
          />
        </div>
      </div>
    </>
  )
}

export default FormularioRecargarCuentaServicios
