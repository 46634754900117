import {getRestApi} from './ApiAxios'

export const ObtenerEmpresaPorId = async (id) => {
  const restApi = await getRestApi()
  return await restApi.get('empresa-function/ObtenerEmpresaPorId?id=' + id)
}

export const ObtenerEmpresasDB = async (fechaUltimaActualizacion) => {
  const restApi = await getRestApi()
  return await restApi.get('empresa-function/ObtenerEmpresas', {
    params: {fecha: fechaUltimaActualizacion ? fechaUltimaActualizacion : ''},
  })
}
