import React, {useEffect, useState, useMemo} from 'react'
import {useTable, usePagination, useRowSelect} from 'react-table'

const TablaDinamica = ({
  titulo,
  data,
  columns,
  paginacion = false,
  fondoTabla = 'bg-cendiatra-verde-7',
  bordeInferior = 'border-b-2 border-b-gris',
  numeroFilas = 10,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canNextPage,
    gotoPage,
    state: {pageIndex},
  } = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0},
    },

    usePagination,
    useRowSelect
  )
  const [numeroInicio, setNumeroInicio] = useState(0)
  const [numeroFinal, setNumeroFinal] = useState(numeroFilas)
  const [numerosParaMostrar, setNumerosparaMostrar] = useState([])

  const datos = useMemo(() => {
    return paginacion ? page : rows
  }, [paginacion, page, rows])

  const crearArrayNumeros = () => {
    if (pageOptions.length > numeroFilas) {
      let numeros = pageOptions.slice(numeroInicio, numeroFinal)
      setNumerosparaMostrar(numeros)
    } else {
      setNumerosparaMostrar(pageOptions)
    }
  }
  useEffect(() => {
    crearArrayNumeros()
  }, [data, pageOptions, numeroInicio])

  const retrocederEnLista = () => {
    if (numeroInicio - numeroFilas > 0) {
      setNumeroInicio(numeroInicio - numeroFilas)
      setNumeroFinal(numeroFinal - numeroFilas)
    } else {
      setNumeroInicio(numeroFilas)
      setNumeroFinal(numeroFilas)
    }
  }
  const avanzarEnLista = () => {
    if (numeroFinal + numeroFilas < pageOptions.length) {
      setNumeroInicio(numeroInicio + numeroFilas)
      setNumeroFinal(numeroFinal + numeroFilas)
    } else {
      setNumeroInicio(pageOptions.length - numeroFilas)
      setNumeroFinal(pageOptions.length)
    }
  }

  const irAlInicio = () => {
    setNumeroInicio(0)
    setNumeroFinal(numeroFilas)
  }

  const irAlFinal = () => {
    setNumeroInicio(pageOptions.length - numeroFilas)
    setNumeroFinal(pageOptions.length)
  }

  return (
    <div className="w-full flex flex-wrap justify-center items-center ">
      <label className="w-full text-start block text-xl text-17px text-cendiatra my-5">
        {titulo}
      </label>
      <table className="w-full" {...getTableProps()}>
        <thead
          className={`w-full flex justify-evenly items-center ${fondoTabla} text-white h-14 rounded-10px`}
          style={{lineHeight: '16px'}}
        >
          {headerGroups.map((headerGroup) => (
            <tr
              className="w-full flex justify-evenly items-center h-full p-3px"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    {
                      className: `${column.className} h-full flex flex-wrap justify-center items-center content-center  m-3px rounded-9px font-light`,
                    },
                  ])}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="w-full " {...getTableBodyProps()}>
          {datos.map((row, i) => {
            prepareRow(row)
            return (
              <tr
                className={`w-full flex ${bordeInferior} justify-evenly items-center text-center text-17px text-cendiatra-gris-1  my-5`}
                {...row.getRowProps()}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      style={{backgroundColor: 'white'}}
                      {...cell.getCellProps({
                        className: `${cell.column.className} uppercase`,
                      })}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div
        className={` w-full ${
          paginacion ? 'flex' : 'hidden'
        } justify-center items-center m-5 h-20 `}
      >
        <div className="flex w-8/12 justify-center items-center">
          <div className="flex">
            <button
              className="text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3"
              onClick={irAlInicio}
            >
              {'«'}
            </button>{' '}
            <button
              className="text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3 mr-3"
              onClick={retrocederEnLista}
            >
              {'‹'}
            </button>{' '}
            {numerosParaMostrar.map((elemento) => (
              <div
                key={elemento}
                className={
                  pageIndex === elemento
                    ? 'z-10 bg-cendiatra border-cendiatra text-white relative inline-flex items-center px-4 border text-13px font-medium cursor-pointer rounded-full '
                    : '  text-cendiatra-gris-1 hover:text-cendiatra-gris-3 relative inline-flex items-center px-4 border text-sm font-medium cursor-pointer rounded-full'
                }
                onClick={() => gotoPage(elemento)}
              >
                {elemento + 1}
              </div>
            ))}
            <button
              className="text-5xl text-cendiatra-gris-1 relative inline-flex items-center pb-3 ml-3"
              onClick={avanzarEnLista}
              disabled={!canNextPage}
            >
              {'›'}
            </button>{' '}
            <button
              className="text-5xl text-cendiatra-gris-1 ml-2 relative inline-flex items-center pb-3"
              onClick={irAlFinal}
              disabled={!canNextPage}
            >
              {'»'}
            </button>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TablaDinamica
