import React from 'react'
import TablaMovientosYDetalles from '../../componentes/usuarioContable/TablaMovimientosYDetalles'
import useValidacionTransaccionPendiente from '../../componentes/usuarioContable/ValidacionTransaccionPendiente'

const DetalleYMovimientos = () => {
  useValidacionTransaccionPendiente()

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex">
          <div className="w-full flex justify-center items-center">
            <h4 className="text-center text-cendiatra text-3xl font-bold">
              MOVIMIENTOS Y DETALLE
            </h4>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <TablaMovientosYDetalles />
      </div>
    </div>
  )
}

export default DetalleYMovimientos
