import React from 'react'
import Carrusel from './Carrusel'

const data = [
  {
    titulo: 'bienvenida',
    enlace: '/',
    url: 'https://prodcendiatra.blob.core.windows.net/banner-portalcliente/Banner-1.png',
  },
  {
    titulo: 'Sedes',
    enlace: '/',
    url: 'https://prodcendiatra.blob.core.windows.net/banner-portalcliente/Banner-2.png',
  },
  {
    titulo: 'trabajando',
    enlace: '/',
    url: 'https://prodcendiatra.blob.core.windows.net/banner-portalcliente/Banner-3.png',
  },
]

const Noticias = () => {
  return (
    <>
      <div className="w-full flex justify-center items-center px-10 mt-10">
        <Carrusel data={data} />
      </div>
    </>
  )
}

export default Noticias
