import React from 'react'

const RedesSociales = ({estilosContenedor, titulo, redes}) => {
  return (
    <div className={`${estilosContenedor}`}>
      <span className="w-full text-center text-cendiatra-gris-2 text-17px">
        {titulo}
      </span>
      <div className="w-full flex justify-center items-center">
        {redes.map((elemento) => (
          <div
            key={elemento.nombre}
            className="w-14 flex justify-center items-center"
          >
            <a className="m-2" href={elemento.enlace} target="_blank">
              <img src={elemento.logo} alt={elemento.nombre} />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RedesSociales
