import React from 'react'
import {CargaArchivoOrdenMasiva} from '../../componentes/Ordenes'
import {BotonSimple} from '../../componentes/Botones'
import {useHistory} from 'react-router-dom'
import { RUTAS_APLICACION } from '../../constantes'

const CargaMasiva = () => {

  const history = useHistory()

  const irAOrdenes = () => {
    history.push(RUTAS_APLICACION.CREAR_ORDEN)
  }

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex">
          <div className="w-9/12">
            <h4
              className="text-center text-cendiatra text-3xl font-bold"
              style={{paddingLeft: 235}}
            >
              CREAR ORDEN MASIVAMENTE
            </h4>
          </div>
          <div className="w-3/12"></div>
        </div>
      </div>
      <div className="container mx-auto w-1/2">
        <div className="w-full items-center justify-center flex mt-10">
          <CargaArchivoOrdenMasiva />
        </div>
        <div className="mt-10 flex justify-center items-center">
          <BotonSimple
            texto={'Volver a crear orden individualmente'}
            estilosBoton={`w-52 h-14 p-1.5 gap-2 flex items-center justify-center rounded-lg bg-fondoBoton bg-cover bg-no-repeat bg-center text-white  font-bold text-14px mx-25px`}
            funcion={irAOrdenes}
          />
        </div>
      </div>
    </div>
  )
}

export default CargaMasiva
