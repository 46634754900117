import React from 'react'
import {NumericFormat} from 'react-number-format'

const CeldaFormatoDinero = ({estilos, valor}) => {
  return (
    <div className="w-full flex justify-center items-center">
      <NumericFormat
        thousandSeparator={'.'}
        decimalSeparator={','}
        decimalScale={2}
        fixedDecimalScale={true}
        prefix={'$'}
        value={valor}
        className={estilos}
      />
    </div>
  )
}

export default CeldaFormatoDinero
