import React, {useMemo} from 'react'
import IconoBorrarActivado from '../../imagenes/iconoBorrarVerde.svg'
import IconoEditarActivado from '../../imagenes/iconoEditarVerde.svg'
import IconoResultadosActivado from '../../imagenes/iconoResultadosVerde.svg'
import IconoImprimirActivado from '../../imagenes/iconoImprimirVerde.svg'
import IconoBorrarDesactivado from '../../imagenes/iconoBorrarGris.svg'
import IconoEditarDesactivado from '../../imagenes/iconoEditarGris.svg'
import IconoResultadosDesactivado from '../../imagenes/iconoResultadosGris.svg'
import IconoImrpimirDesactivado from '../../imagenes/iconoImprimirGris.svg'
import BotonConImagen from '../Botones/BotonConImagen'
import {TEXTO_VISUAL} from '../../constantes'

const CeldaModulos = ({
  estado,
  funcionEditar,
  funcionBorrar,
  funcionResultados,
  funcionImprimir,
  mostrarAtenciones,
}) => {
  const edicionActiva = useMemo(
    () => [
      TEXTO_VISUAL.ORDENES.ESTADO.BORRADOR,
      TEXTO_VISUAL.ORDENES.ESTADO.ABIERTA,
    ],
    []
  )
  const borrarActiva = useMemo(
    () => [
      TEXTO_VISUAL.ORDENES.ESTADO.BORRADOR,
      TEXTO_VISUAL.ORDENES.ESTADO.ABIERTA,
      TEXTO_VISUAL.ORDENES.ESTADO.VENCIDA,
    ],
    []
  )
  const resultadosYImprimirActiva = useMemo(
    () => [TEXTO_VISUAL.ATENCIONES.ATENDIDA],
    []
  )

  return (
    <div
      className="w-full flex justify-center items-center"
      style={{lineHeight: '15px'}}
    >
      {!mostrarAtenciones ? (
        <>
          <div className="w-2/4 flex flex-wrap justify-center items-center">
            <BotonConImagen
              imagen={
                edicionActiva.includes(estado)
                  ? IconoEditarActivado
                  : IconoEditarDesactivado
              }
              textoAlternativo={'Editar'}
              funcionAEjecutar={
                edicionActiva.includes(estado) ? funcionEditar : () => {}
              }
              estilosImagen={'w-13 h-12'}
            />
          </div>
          <div className="w-2/4 flex flex-wrap justify-center items-center">
            <BotonConImagen
              imagen={
                borrarActiva.includes(estado)
                  ? IconoBorrarActivado
                  : IconoBorrarDesactivado
              }
              textoAlternativo={'Borrar'}
              funcionAEjecutar={
                borrarActiva.includes(estado) ? funcionBorrar : () => {}
              }
              estilosImagen={'w-13 h-12'}
            />
          </div>
        </>
      ) : (
        <>
          <div className="w-2/4 flex flex-wrap justify-center items-center">
            <BotonConImagen
              imagen={
                resultadosYImprimirActiva.includes(estado)
                  ? IconoResultadosActivado
                  : IconoResultadosDesactivado
              }
              textoAlternativo={'Resultados'}
              funcionAEjecutar={
                resultadosYImprimirActiva.includes(estado)
                  ? funcionResultados
                  : () => {}
              }
              estilosImagen={'w-13 h-12'}
            />
          </div>
          <div className="w-2/4 flex flex-wrap justify-center items-center">
            <BotonConImagen
              imagen={
                resultadosYImprimirActiva.includes(estado)
                  ? IconoImprimirActivado
                  : IconoImrpimirDesactivado
              }
              textoAlternativo={'Imprimir'}
              funcionAEjecutar={
                resultadosYImprimirActiva.includes(estado)
                  ? funcionImprimir
                  : () => {}
              }
              estilosImagen={'w-13 h-12'}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default CeldaModulos
