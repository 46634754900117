import Dexie from 'dexie'
import {ObtenerPaquetes} from '../../microservicios'
import {dbCliente} from '../bases-datos'

export const AgregarPaquetesACache = async () => {
  await dbCliente.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbCliente.table('FechasActualizacion').get({Tabla: 'Paquetes'})
    )?.FechaUltimaActualizacion
    await ObtenerPaquetes(fechaUltimaActualizacion).then((res) => {
      dbCliente.Paquetes.bulkPut(
        res.data.map((paquete) => ({
          Id: paquete.id,
          CodigoInterno: paquete.codigoInterno,
          Nombre: paquete.nombre,
          Servicios: paquete.servicios,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar paquetes al caché cliente: ' + (e.stack || e)
        )
      })
      dbCliente.FechasActualizacion.put({
        Tabla: 'Paquetes',
        FechaUltimaActualizacion: res.headers.tiemposervidor,
      })
    })
  })
}

export const ObtenerPaquetePorId = async (id) => {
  return Promise.resolve(dbCliente.table('Paquetes').get({Id: id}))
}
