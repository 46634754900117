import {FACTURACION} from './facturacion'

/** Textos a mostrar en la interfaz gráfica. */
export const TEXTO_VISUAL = {
  /** Pantalla inicial. */
  HOME: {
    /** Opciones de la barra de navegación. */
    NAVEGACION: {
      INICIO: 'Inicio',
      GESTION_DE_ORDENES: 'Gestión de órdenes',
      CREAR_GESTIONAR_ORDENES: 'Crear y gestionar órdenes',
      GESTIONAR_ORDENES: 'Gestiónar órdenes',
      GESTION_ATENCIONES: 'Gestión de atenciones',
      CREAR_ORDEN: 'Crear órdenes',
      CERRAR_SESION: 'Cerrar sesión',
      REALIZAR_PAGOS: 'Realizar Pagos',
      FACTURACION: 'Facturación',
      RECARGAR_CUENTA: 'Recarga cuenta',
      PAGAR_OTROS_SERVICIOS: 'Pagar otros servicios',
      VER_MOVIMIENTOS: 'Ver movimientos y detalles',
      DESCARGA_MASIVA: 'Descarga masiva de conceptos',
    },
    /** Textos del lateral. */
    LATERAL: {
      NOMBRE_APLICACION: 'Portal Cliente',
      BIENVENIDA: 'Bienvenido',
      MES_ACTUAL: 'Mes actual',
      ORDENES_READAS: 'Pacientes atendidos',
      PACIENTE_CON_ORDEN: 'Pacientes atendidos con orden',
      PACIENTE_SIN_ORDEN: 'Pacientes atendidos sin orden',
      CERTIFICADOS_PENDIENTES_DESCARGA: 'Órdenes de servicio creadas',
      ORDENES_ATENDIDAS:
        'Barra de progreso de pacientes atendidos vs. órdenes creadas',
      SIGUENOS: 'Síguenos',
      OPCIONES: {
        DESCARGAR_MANUAL_USUARIO: 'Descargar manual de usuario',
        CERTIFICACION_DE_CUSTODIA_DE_HISTORIA_CLINICA:
          'Certificación de custodia de historia clínica',
      },
      SALDO_DISPONIBLE: 'Saldo disponible',
      ULTIMA_RECARGA: 'Última recarga',
      VER_MOVIMIENTOS_Y_DETALLES: 'Ver movimientos y detalles',
    },
    /** Gráficos  */
    GRAFICOS: {
      ORDENES: {
        TITULO: '',
        POR_SEMANA_ACTUAL: 'Órdenes por semana actual',
        LEYENDAS: {
          ORDENES_CREADAS: 'ÓRDENES CREADAS',
          ORDENES_ATENDIDAS: 'ÓRDENES ATENDIDAS',
        },
      },
      EXAMENES_MAS_FRECUENTES: {
        TITULO: 'Exámenes más frecuentes',
        TOOLTIP: {
          CANTIDAD: 'Cantidad',
        },
      },
      FACTURACION: {
        TITULO: '',
        ULTIMO_MES: 'Ejecución mes en curso',
        TOTAL_FACTURADO: 'Total ejecutado',
        TOOLTIP: {
          DINERO_FACTURADO: 'Dinero facturado',
        },
      },
    },
    ROL: {
      MEDICO: 'medico',
      ADMINISTRATIVO: 'administrativo',
    },
    TIPOS_FACTURACION: {
      CONTADO: 'CONTADO',
      CREDITO: 'CRÉDITO',
      CONTRATO: 'CONTRATO',
    },
  },
  ORDENES: {
    ESTADO: {
      BORRADOR: 'BORRADOR',
      ABIERTA: 'ABIERTA',
      PROCESO: 'EN PROCESO',
      ATENDIDA: 'ATENDIDA',
      CANCELADA: 'CANCELADA',
      VENCIDA: 'VENCIDA',
    },
  },
  ATENCIONES: {
    ACTIVA: 'ACTIVA',
    CANCELADO: 'CANCELADO',
    EN_PROCESO: 'EN PROCESO',
    EN_PROCESO_CANCELACION: 'EN PROCESO CANCELACIÓN',
    EN_PROCESO_MODIFICACION: 'EN PROCESO MODIFICACIÓN',
    ATENDIDA: 'ATENDIDA',
  },

  DETALLE_DE_LA_ATENCION: {
    TITULO: 'DETALLE DE LA ATENCIÓN ',
  },

  TIPO_MEDICO: {
    MEDICO_ALIADO: 'MEDICO ALIADO',
  },

  COLUMNA_DETALLE_ATENCION: {
    CERTIFICADO: 'certificado',
    EXAMEN: 'examen',
  },

  OTROS_SERVICIOS: {
    TITULO: 'PAGAR OTROS SERVICIOS',
    SUBTITULO:
      '(Tiqueteras, Profesiogramas, Análisis de puesto de trabajo y Batería de riesgo psicosocial)',
  },
}
