export const encabezadosExportarOrdenes = [
  {label: 'Id', key: 'id'},
  {label: 'Identificador', key: 'identificador'},
  {
    label: 'Centro de Costos o Empresa Usuaria',
    key: 'centroCostosEmpresaUsuaria',
  },
  {label: 'Estado', key: 'estado'},
  {label: 'Número de Orden', key: 'numeroOrdenyFechaCreacion.Fila1'},
  {label: 'Fecha de creación', key: 'numeroOrdenyFechaCreacion.Fila2'},
  {label: 'Nombre', key: 'nombresyDocumento.Fila1'},
  {label: 'Número Documento', key: 'nombresyDocumento.Fila2'},
  {label: 'Tipo de examen', key: 'tipoExamen'},
  {label: 'Sede', key: 'sedeyFechaAtencion.Fila1'},
  {label: 'Fecha de atención', key: 'sedeyFechaAtencion.Fila2'},
  {label: 'Concepto', key: 'concepto'},
]

export const encabezadosExportarAtenciones = [
  {label: 'Id', key: 'atencionId'},
  {label: 'Identificador', key: 'identificador'},
  {
    label: 'Centro de Costos o Empresa Usuaria',
    key: 'centroCostosEmpresaUsuaria',
  },
  {label: 'Estado', key: 'estadoAtencion'},
  {label: 'Número de Orden', key: 'numeroOrdenyFechaCreacion.Fila1'},
  {label: 'Fecha de creación', key: 'numeroOrdenyFechaCreacion.Fila2'},
  {label: 'Nombre', key: 'nombresyDocumento.Fila1'},
  {label: 'Número Documento', key: 'nombresyDocumento.Fila2'},
  {label: 'Tipo de examen', key: 'tipoExamen'},
  {label: 'Sede', key: 'sedeyFechaAtencion.Fila1'},
  {label: 'Fecha de atención', key: 'sedeyFechaAtencion.Fila2'},
  {label: 'Concepto', key: 'concepto'},
]
