import React from 'react'

const ConfirmacionOrden = ({texto, textoAdicional}) => {
  return (
    <div className="w-full flex flex-wrap m-2">
      <span className="w-full text-cendiatra text-xl font-bold mb-3">{textoAdicional}</span>
      <span className="w-full text-cendiatra text-xl font-bold">{texto}</span>
    </div>
  )
}

export default ConfirmacionOrden