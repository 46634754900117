const TablaConcepto = ({servicios}) => {
  const tablaStyle = {
    borderTop: 'none',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    marginTop: '-23px',
    paddingTop: '23px',
  }

  return (
    <div
      className="w-full border border-cendiatra rounded-xl -mt-3 -z-1 -scroll-mt-20"
      style={tablaStyle}
    >
      <table className="w-full">
        <thead
          className="w-full flex justify-start px-16 items-center  text-cendiatra h-14 rounded-2xl"
          style={{lineHeight: '16px'}}
        >
          <th>EXAMEN</th>
          <th></th>
        </thead>
        <tbody
          className="bg-grey-light flex flex-col overflow-auto w-full border-cendiatra rounded-2xl"
          style={{maxHeight: '250px'}}
        >
          {servicios?.map((servicio, index) => (
            <tr
              key={index}
              className="w-full text-cendiatra-gris-1  flex text-center  px-16 py-2 border"
            >
              <td className="text-cendiatra-gris-1 flex w-1/2">
                {servicio.nombreServicio}
              </td>

              <td className="text-cendiatra-gris-1 flex w-1/2">
                {servicio.descripcionConcepto !== null ? (
                  <span className="text-cendiatra mr-3 font-bold">
                    {' '}
                    CONCEPTO:
                  </span>
                ) : null}
                {servicio.descripcionConcepto}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default TablaConcepto
