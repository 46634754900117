import Dexie from 'dexie'
import {ObtenerServicios} from '../../microservicios'
import {dbCliente} from '../bases-datos'

export const AgregarServiciosACache = async () => {
  await dbCliente.open().then(async () => {
    let fechaUltimaActualizacion = (
      await dbCliente.table('FechasActualizacion').get({Tabla: 'Servicios'})
    )?.FechaUltimaActualizacion
    await ObtenerServicios(fechaUltimaActualizacion).then((res) => {
      dbCliente.Servicios.bulkPut(
        res.data.map((servicio) => ({
          Id: servicio.id,
          CodigoInterno: servicio.codigoInterno,
          Nombre: servicio.nombre,
          CupsId: servicio.cupsId,
        }))
      ).catch(Dexie.BulkError, function (e) {
        console.error(
          'Error al agregar servicios al caché cliente: ' + (e.stack || e)
        )
      })
      dbCliente.FechasActualizacion.put({
        Tabla: 'Servicios',
        FechaUltimaActualizacion: res.headers.tiemposervidor,
      })
    })
  })
}

export const ObtenerServiciosOPaquetesPorTarifario = async (
  tarifario,
  filtro
) => {
  let serviciosPorAlias = (
    await dbCliente
      .table('Servicios')
      .filter(
        ({Id}) =>
          tarifario?.servicios?.map((x) => x.servicioId)?.includes(Id) &&
          tarifario?.servicios
            ?.find((x) => x.servicioId == Id)
            ?.alias?.toUpperCase()
            ?.indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map((elemento) => ({
    Id: elemento.Id,
    Nombre:
      tarifario?.servicios?.find((x) => x.servicioId == elemento.Id)?.alias ??
      elemento.Nombre,
    Tipo: 'Servicio',
  }))
  let serviciosPorNombre = (
    await dbCliente
      .table('Servicios')
      .filter(
        ({Id, Nombre}) =>
          tarifario?.servicios?.map((x) => x.servicioId)?.includes(Id) &&
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1 &&
          !serviciosPorAlias.map((x) => x.Id).includes(Id)
      )
      .toArray()
  ).map((elemento) => ({
    Id: elemento.Id,
    Nombre:
      tarifario?.servicios?.find((x) => x.servicioId == elemento.Id)?.alias ??
      elemento.Nombre,
    Tipo: 'Servicio',
  }))
  let paquetesPorNombre = (
    await dbCliente
      .table('Paquetes')
      .filter(
        ({Id, Nombre}) =>
          tarifario?.paquetes?.map((x) => x.paqueteId)?.includes(Id) &&
          Nombre.toUpperCase().indexOf(filtro.toUpperCase()) > -1
      )
      .toArray()
  ).map(async (elemento) => ({
    Id: elemento.Id,
    Nombre: elemento.Nombre,
    Tipo: 'Paquete',
    ServiciosPaquete: await Promise.all(
      elemento.Servicios.map(async function (x) {
        return await ObtenerServicioPorId(x)
      })
    ),
  }))
  return Promise.all([
    ...serviciosPorAlias,
    ...serviciosPorNombre,
    ...paquetesPorNombre,
  ])
}

export const ObtenerServiciosYPaquetesPorTarifario = async (tarifario) => {
  const servicios = (
    await dbCliente
      .table('Servicios')
      .filter(({Id}) =>
        tarifario?.servicios?.map(({servicioId}) => servicioId).includes(Id)
      )
      .toArray()
  ).map((item) => ({
    Id: item.Id,
    Nombre:
      tarifario?.servicios?.find(({servicioId}) => servicioId == item.Id)
        ?.alias ?? item.Nombre,
    Tipo: 'Servicio',
  }))

  const paquetes = (
    await dbCliente
      .table('Paquetes')
      .filter(({Id}) =>
        tarifario?.paquetes?.map(({paqueteId}) => paqueteId).includes(Id)
      )
      .toArray()
  ).map(async (item) => ({
    Id: item.Id,
    Nombre: item.Nombre,
    Tipo: 'Paquete',
    ServiciosPaquete: await Promise.all(
      item?.Servicios?.map(async (id) => {
        return await ObtenerServicioPorId(id)
      })
    ),
  }))
  return Promise.all([...servicios, ...paquetes])
}

export const ObtenerServicioPorId = async (id) => {
  return Promise.resolve(dbCliente.table('Servicios').get({Id: id}))
}
