import React from 'react'

export const CeldaDobleTexto = ({value: initialValue}) => {
  return (
    <div className="w-full flex flex-wrap justify-evenly items-center">
      <span className="w-full flex justify-evenly items-center uppercase">
        {initialValue.Fila1}
      </span>
      <span className="w-full flex justify-evenly items-center uppercase">
        {initialValue.Fila2}
      </span>
    </div>
  )
}
