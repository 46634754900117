import React from 'react'
import Slider from 'react-slick'

// Data
const Carrusel = ({data}) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    cssEase: 'linear',
    pauseOnHover: true,
    adaptiveHeight: true,
    nextArrow: <FlechaAdelante />,
    prevArrow: <FlechaAtras />,
  }
  function FlechaAdelante(props) {
    const {className, style, onClick} = props
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          marginRight: '40px',
          zIndex: 'revert',
        }}
        onClick={onClick}
      />
    )
  }
  function FlechaAtras(props) {
    const {className, style, onClick} = props
    return (
      <div
        className={className}
        style={{...style, display: 'block', marginLeft: '40px', zIndex: '10'}}
        onClick={onClick}
      />
    )
  }
  return (
    <div className="h-full w-full">
      <Slider {...settings}>
        {data.map((noticia) => (
          <div key={noticia.titulo} className={'w-full h-full'}>
            <img
              className="w-full h-full rounded-lg"
              src={noticia.url}
              alt="noticia.titulo"
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default Carrusel
