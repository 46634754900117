import React, {useContext, useEffect, useState} from 'react'
import FormularioRecargarCuentaServicios from '../../componentes/usuarioContable/FormularioRecargarCuentaServicios'
import {ContextoRecargarSaldo, ContextoAplicacion} from '../../contexto'
import {useMsal} from '@azure/msal-react'
import {RecargarSaldoCuenta} from '../../microservicios'
import {useHistory} from 'react-router-dom'
import useValidacionTransaccionPendiente from '../../componentes/usuarioContable/ValidacionTransaccionPendiente'
import {obtenerUrlRedirect} from '../../config'
import {ObtenerCiudadPorId} from '../../cache/servicios-cache'

const RecargarSaldo = () => {
  useValidacionTransaccionPendiente()

  const contextoAplicacion = useContext(ContextoAplicacion)
  const contextoRecargarSaldo = useContext(ContextoRecargarSaldo)
  const [urlApp, setUrlApp] = useState('')
  const history = useHistory()
  const {accounts} = useMsal()
  const idEmpresa = accounts[0]?.idTokenClaims?.city ?? ''

  const [ip, setIp] = useState('')

  function obtenerDireccionIP() {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        setIp(data.ip)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    obtenerDireccionIP()
  }, [])

  useEffect(() => {
    obtenerUrlRedirect()
      .then((response) => {
        setUrlApp(response)
      })
      .catch((err) => {
        console.error('no se pudo obtener la url de redireccion', err)
      })
  }, [])

  const enviarInformacionRecarga = (datosTabla, municipio) => {
    const medioDePagoPSE = 3
    const payload = {
      empresaId: idEmpresa,
      valor: parseFloat(datosTabla?.montoRecarga?.toFixed(2)),
      valorRecibido: parseFloat(datosTabla?.valorRecibido.toFixed(2)),
      detalleRecarga: contextoRecargarSaldo.formularioRecargarSaldo.detalle,
      ip: ip,
      UrlRetornoComercio: urlApp,
      municipioId: municipio?.Id,
      retefuente: datosTabla?.retencionRenta || 0,
      reteIca: datosTabla?.retencionICA || 0,
      bomberil: datosTabla?.sobretasaBomberil || 0,
      mediosDePago: [
        {
          medioPagoId: medioDePagoPSE,
          valor: parseFloat(datosTabla?.valorRecibido?.toFixed(2)),
        },
      ],
    }
    recargarSaldo(payload)
  }

  async function recargarSaldo(payload) {
    try {
      const res = await RecargarSaldoCuenta(payload)
      if (res.data) {
        const empresaData = {
          documento: contextoRecargarSaldo.dataPdf.documento,
          nombre: contextoRecargarSaldo.dataPdf.nombre,
          direccion: contextoRecargarSaldo.dataPdf.direccion,
          telefono: contextoRecargarSaldo.dataPdf.telefono,
          municipio:
            (await ObtenerCiudadPorId(payload.municipioId ?? 0))?.Nombre ?? '',
        }

        sessionStorage.setItem('movimientoId', res?.data?.movimientoId)
        sessionStorage.setItem('empresaData', JSON.stringify(empresaData))

        const urlAvalPay = res?.data?.urlPasarelaPagos
        window.open(urlAvalPay, '_self')
      }
    } catch (error) {
      manejarModalError()
    }
  }

  const manejarCerrarModal = () => {
    contextoAplicacion.setModal({
      abierto: false,
      titulo: '',
      contenido: '',
      botones: [],
    })
  }

  const irAInicio = () => {
    manejarCerrarModal()
    history.push('/')
  }

  const manejarModalError = () => {
    contextoAplicacion.setModal({
      abierto: true,
      titulo: 'ERROR',
      contenido: (
        <div className="w-full flex justify-center items-center text-cendiatra text-3xl my-10 font-bold flex-wrap">
          <span className="w-full">Tu cuenta NO ha sido recargada</span>
          <span className="w-full">¿Qué deseas hacer?</span>
        </div>
      ),
      botones: [
        {
          nombre: 'Regresar a recargar cuenta',
          click: () => manejarCerrarModal(),
        },
        {
          nombre: 'Finalizar',
          click: () => irAInicio(),
        },
      ],
    })
  }

  return (
    <div className="w-full pt-10 pb-10">
      <div className="container mx-auto flex justify-center">
        <div className="w-9/12 flex">
          <div className="w-full flex justify-center items-center">
            <h4 className="text-center text-cendiatra text-3xl font-bold">
              RECARGA TU CUENTA DE SERVICIOS
            </h4>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <FormularioRecargarCuentaServicios
          manejarGuardar={enviarInformacionRecarga}
        />
      </div>
    </div>
  )
}

export default RecargarSaldo
