export const ESTADOS_TRANSACCION = {
    PENDIENTE: 'Pendiente',
    RECHAZADA: 'Rechazada',
    FALLIDA: 'Fallida',
    APROBADA: 'Aprobada',
    EXPIRADA: 'Expirada',
    NO_AUTORIZADA: 'No_Autorizada'
  }


  export const ESTADOS_TRANSACCION_MOVIMIENTOS = {
    PENDIENTE: 1,
  }