const HeaderModulosTablaOrdenes = ({mostrarAtenciones}) => {
  return (
    <div className="w-full">
      <div className="w-full py-2">Módulos</div>
      <div className="w-full flex justify-center items-center">
        {!mostrarAtenciones ? (
          <>
            <div className="w-2/4">Editar</div>
            <div className="w-2/4">Borrar</div>
          </>
        ) : (
          <>
            <div className="w-2/4">Ver atención</div>
            <div className="w-2/4">Imprimir certificado</div>
          </>
        )}
      </div>
    </div>
  )
}

export default HeaderModulosTablaOrdenes
