import React, {useState, createContext} from 'react'
import moment from 'moment'

export const ContextoOrden = createContext('')

export const OrdenConContexto = ({children}) => {
  const [formularioOrden, setFormularioOrden] = useState({
    ciudad: '',
    tipoSubmit: '',
    usuario: {},
    fechaCreacion: moment().format('YYYY-MM-DD'),
  })
  const [examen, setExamen] = useState('')
  const [examenesSeleccionados, setExamenesSeleccionados] = useState([])
  const [examenParaAgregar, setExamenParaAgregar] = useState('')
  const [ciudadesFiltradas, setCiudadesFiltradas] = useState([])
  const [examenesFiltrados, setExamenesFiltrados] = useState([])
  const [tipoDeSubmit, setTipoDeSubmit] = useState('')
  const [actualizar, setActualizar] = useState(false)
  const [descontarSaldo, setDescontarSaldo] = useState(false)
  const [estadoOrden, setEstadoOrden] = useState('')
  const [tarifario, setTarifario] = useState({})
  const [indicativo, setIndicativo] = useState('')

  return (
    <ContextoOrden.Provider
      value={{
        formularioOrden: formularioOrden,
        setFormularioOrden: setFormularioOrden,
        ciudadesFiltradas: ciudadesFiltradas,
        setCiudadesFiltradas: setCiudadesFiltradas,
        examen: examen,
        setExamen: setExamen,
        examenesSeleccionados: examenesSeleccionados,
        setExamenesSeleccionados: setExamenesSeleccionados,
        examenParaAgregar: examenParaAgregar,
        setExamenParaAgregar: setExamenParaAgregar,
        examenesFiltrados: examenesFiltrados,
        setExamenesFiltrados: setExamenesFiltrados,
        tipoDeSubmit: tipoDeSubmit,
        setTipoDeSubmit: setTipoDeSubmit,
        actualizar: actualizar,
        setActualizar: setActualizar,
        estadoOrden: estadoOrden,
        setEstadoOrden: setEstadoOrden,
        tarifario: tarifario,
        setTarifario: setTarifario,
        descontarSaldo: descontarSaldo,
        setDescontarSaldo: setDescontarSaldo,
        indicativo: indicativo,
        setIndicativo: setIndicativo,
      }}
    >
      {children}
    </ContextoOrden.Provider>
  )
}
