import React, {useEffect, useState} from 'react'
import {NavLink, useLocation} from 'react-router-dom'
import {RUTAS_APLICACION} from '../../constantes'

const MenuDinamico = ({datos}) => {
  const [linkActivo, setLinkActivo] = useState('/')

  const [linkEnHover, setLinkEnHover] = useState('')

  const ubicacion = useLocation()

  const paginaActual = window.location.pathname

  useEffect(() => {
    setLinkActivo(ubicacion?.pathname)
  }, [ubicacion?.pathname])

  const manejarPestanaSeleccionada = (elementoSeleccionado) => {
    setLinkActivo(elementoSeleccionado?.ruta)
    if (elementoSeleccionado.funcion) {
      elementoSeleccionado.funcion()
    }
  }

  return (
    <div
      className="flex justify-between items-center bg-white w-auto rounded rounded-t-xl rounded-b-xl border  border-opacity-50 border-cendiatra shadow-2xl p-2"
      style={{zIndex: 10000}}
      onMouseLeave={() => setLinkEnHover('')}
    >
      {datos.map((elemento) => (
        <NavLink
          key={elemento?.texto}
          className={`h-24 w-36 flex flex-wrap items-center rounded ${
            elemento?.deshailitado ? 'pointer-events-none' : ''
          } hover:bg-cendiatra-gris-3`}
          to={elemento?.subMenus ? paginaActual : elemento?.ruta ?? ''}
        >
          <div
            className="w-full h-24 p-1 flex flex-col justify-start items-center rounded hover:bg-gray-200"
            onClick={
              elemento?.subMenus
                ? null
                : () => manejarPestanaSeleccionada(elemento)
            }
            onMouseEnter={() => setLinkEnHover(elemento?.ruta)}
          >
            <img
              src={
                linkActivo === elemento?.ruta ||
                (linkActivo === RUTAS_APLICACION.MAIN &&
                  elemento?.ruta === RUTAS_APLICACION.HOME) ||
                elemento?.subMenus?.some(
                  (subMenu) => subMenu?.ruta === linkActivo
                )
                  ? elemento?.imagen
                  : elemento?.imagenDeshabilitado
              }
              alt={elemento?.texto}
              className="block h-10 w-10 cursor-pointer"
            />
            <span
              className={`block text-center ${
                linkActivo === elemento?.ruta ||
                (linkActivo === RUTAS_APLICACION.MAIN &&
                  elemento?.ruta === RUTAS_APLICACION.HOME)
                  ? 'text-cendiatra-gris-3'
                  : 'text-cendiatra-gris-placeholder'
              } mt-1 cursor-pointer`}
            >
              {elemento?.texto}
            </span>

            {elemento?.subMenus ? (
              linkEnHover === elemento?.ruta ? (
                <div
                  className={`w-36 h-auto ${
                    linkEnHover === elemento?.ruta ? 'flex flex-wrap' : 'hidden'
                  } fixed top-28 bg-white z-50 rounded border-l-2 border-r-2 border-t-2 `}
                  onClick={() => setLinkActivo('')}
                  onMouseEnter={() => setLinkEnHover(elemento?.ruta)}
                >
                  {elemento?.subMenus.map((subMenu) => (
                    <NavLink
                      to={subMenu?.ruta}
                      className="flex w-full justify-start flex-nowrap items-center text-cendiatra-gris-1 p-2 border-b-2 hover:bg-gray-100 hover:text-cendiatra-verde-2"
                    >
                      <span className={` ml-2`}>{subMenu?.titulo}</span>
                    </NavLink>
                  ))}
                </div>
              ) : null
            ) : null}
          </div>
        </NavLink>
      ))}
    </div>
  )
}

export default MenuDinamico
