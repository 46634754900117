import React from 'react'

const Input = ({
  onChange,
  onBlur,
  estilosContenedor,
  estilosInput,
  tipo,
  placeholder,
  titulo,
  deshabilitado,
  fechaMinima,
  fechaMaxima,
  valor,
  nombre,
}) => {
  return (
    <div
      className={`${estilosContenedor} flex justify-start items-center flex-wrap`}
    >
      <label className="block text-13px font-medium text-cendiatra my-5px w-full">
        {titulo}
      </label>
      <input
        className={estilosInput}
        type={tipo}
        placeholder={placeholder}
        disabled={deshabilitado}
        onChange={onChange}
        value={valor}
        min={fechaMinima}
        max={fechaMaxima}
        onBlur={onBlur}
        name={nombre}
      />
    </div>
  )
}

export default Input
